// @ts-nocheck
import { useMutation, useQuery } from '@tanstack/react-query';
import { fetcherWithGraphQLClient } from '@/configurations/reactQuery/fetcher';
/** The agency activity Type UNSPECIFIED|CREATED|EDITED|DELETED|ENABLED|DISABLED|CONSOLID_FEE_MODULE_EDITED|COMMISSION_MODULE_EDITED */
export var AgencyActivityType;
(function (AgencyActivityType) {
    AgencyActivityType["AgencyActivityTypeCommissionModuleEdited"] = "AGENCY_ACTIVITY_TYPE_COMMISSION_MODULE_EDITED";
    AgencyActivityType["AgencyActivityTypeConsolidFeeModuleEdited"] = "AGENCY_ACTIVITY_TYPE_CONSOLID_FEE_MODULE_EDITED";
    AgencyActivityType["AgencyActivityTypeCreated"] = "AGENCY_ACTIVITY_TYPE_CREATED";
    AgencyActivityType["AgencyActivityTypeCreditEdited"] = "AGENCY_ACTIVITY_TYPE_CREDIT_EDITED";
    AgencyActivityType["AgencyActivityTypeDeleted"] = "AGENCY_ACTIVITY_TYPE_DELETED";
    AgencyActivityType["AgencyActivityTypeDisabled"] = "AGENCY_ACTIVITY_TYPE_DISABLED";
    AgencyActivityType["AgencyActivityTypeEdited"] = "AGENCY_ACTIVITY_TYPE_EDITED";
    AgencyActivityType["AgencyActivityTypeEnabled"] = "AGENCY_ACTIVITY_TYPE_ENABLED";
    AgencyActivityType["AgencyActivityTypeUnspecified"] = "AGENCY_ACTIVITY_TYPE_UNSPECIFIED";
})(AgencyActivityType || (AgencyActivityType = {}));
/** The agency consolid fee module activity Type UNSPECIFIED|CREATED|EDITED|DELETED */
export var AgencyConsolidFeeModuleActivityType;
(function (AgencyConsolidFeeModuleActivityType) {
    AgencyConsolidFeeModuleActivityType["AgencyConsolidFeeModuleActivityTypeCreated"] = "AGENCY_CONSOLID_FEE_MODULE_ACTIVITY_TYPE_CREATED";
    AgencyConsolidFeeModuleActivityType["AgencyConsolidFeeModuleActivityTypeDeleted"] = "AGENCY_CONSOLID_FEE_MODULE_ACTIVITY_TYPE_DELETED";
    AgencyConsolidFeeModuleActivityType["AgencyConsolidFeeModuleActivityTypeEdited"] = "AGENCY_CONSOLID_FEE_MODULE_ACTIVITY_TYPE_EDITED";
    AgencyConsolidFeeModuleActivityType["AgencyConsolidFeeModuleActivityTypeUnspecified"] = "AGENCY_CONSOLID_FEE_MODULE_ACTIVITY_TYPE_UNSPECIFIED";
})(AgencyConsolidFeeModuleActivityType || (AgencyConsolidFeeModuleActivityType = {}));
/** The Airline Rule Activity Type */
export var AirlineRuleActivityType;
(function (AirlineRuleActivityType) {
    AirlineRuleActivityType["AirlineRuleActivityTypeCreated"] = "AIRLINE_RULE_ACTIVITY_TYPE_CREATED";
    AirlineRuleActivityType["AirlineRuleActivityTypeDeleted"] = "AIRLINE_RULE_ACTIVITY_TYPE_DELETED";
    AirlineRuleActivityType["AirlineRuleActivityTypeDisabled"] = "AIRLINE_RULE_ACTIVITY_TYPE_DISABLED";
    AirlineRuleActivityType["AirlineRuleActivityTypeEdited"] = "AIRLINE_RULE_ACTIVITY_TYPE_EDITED";
    AirlineRuleActivityType["AirlineRuleActivityTypeEnabled"] = "AIRLINE_RULE_ACTIVITY_TYPE_ENABLED";
    AirlineRuleActivityType["AirlineRuleActivityTypeUnspecified"] = "AIRLINE_RULE_ACTIVITY_TYPE_UNSPECIFIED";
})(AirlineRuleActivityType || (AirlineRuleActivityType = {}));
/** The Airline Rule Condition Operator Type */
export var AirlineRuleConditionOperatorType;
(function (AirlineRuleConditionOperatorType) {
    AirlineRuleConditionOperatorType["AirlineRuleConditionOperatorTypeAfter"] = "AIRLINE_RULE_CONDITION_OPERATOR_TYPE_AFTER";
    AirlineRuleConditionOperatorType["AirlineRuleConditionOperatorTypeBefore"] = "AIRLINE_RULE_CONDITION_OPERATOR_TYPE_BEFORE";
    AirlineRuleConditionOperatorType["AirlineRuleConditionOperatorTypeBetween"] = "AIRLINE_RULE_CONDITION_OPERATOR_TYPE_BETWEEN";
    AirlineRuleConditionOperatorType["AirlineRuleConditionOperatorTypeEqual"] = "AIRLINE_RULE_CONDITION_OPERATOR_TYPE_EQUAL";
    AirlineRuleConditionOperatorType["AirlineRuleConditionOperatorTypeGrater"] = "AIRLINE_RULE_CONDITION_OPERATOR_TYPE_GRATER";
    AirlineRuleConditionOperatorType["AirlineRuleConditionOperatorTypeLesser"] = "AIRLINE_RULE_CONDITION_OPERATOR_TYPE_LESSER";
    AirlineRuleConditionOperatorType["AirlineRuleConditionOperatorTypeNotEqual"] = "AIRLINE_RULE_CONDITION_OPERATOR_TYPE_NOT_EQUAL";
})(AirlineRuleConditionOperatorType || (AirlineRuleConditionOperatorType = {}));
/** The Airline Rule Condition Type */
export var AirlineRuleConditionType;
(function (AirlineRuleConditionType) {
    AirlineRuleConditionType["AirlineRuleConditionTypeAccountCode"] = "AIRLINE_RULE_CONDITION_TYPE_ACCOUNT_CODE";
    AirlineRuleConditionType["AirlineRuleConditionTypeCabin"] = "AIRLINE_RULE_CONDITION_TYPE_CABIN";
    AirlineRuleConditionType["AirlineRuleConditionTypeClassOfService"] = "AIRLINE_RULE_CONDITION_TYPE_CLASS_OF_SERVICE";
    AirlineRuleConditionType["AirlineRuleConditionTypeCodeshare"] = "AIRLINE_RULE_CONDITION_TYPE_CODESHARE";
    AirlineRuleConditionType["AirlineRuleConditionTypeDepartureDate"] = "AIRLINE_RULE_CONDITION_TYPE_DEPARTURE_DATE";
    AirlineRuleConditionType["AirlineRuleConditionTypeDestinationAirport"] = "AIRLINE_RULE_CONDITION_TYPE_DESTINATION_AIRPORT";
    AirlineRuleConditionType["AirlineRuleConditionTypeDestinationCity"] = "AIRLINE_RULE_CONDITION_TYPE_DESTINATION_CITY";
    AirlineRuleConditionType["AirlineRuleConditionTypeDestinationContinent"] = "AIRLINE_RULE_CONDITION_TYPE_DESTINATION_CONTINENT";
    AirlineRuleConditionType["AirlineRuleConditionTypeDestinationCountry"] = "AIRLINE_RULE_CONDITION_TYPE_DESTINATION_COUNTRY";
    AirlineRuleConditionType["AirlineRuleConditionTypeDomestic"] = "AIRLINE_RULE_CONDITION_TYPE_DOMESTIC";
    AirlineRuleConditionType["AirlineRuleConditionTypeFareBasisClassOfService"] = "AIRLINE_RULE_CONDITION_TYPE_FARE_BASIS_CLASS_OF_SERVICE";
    AirlineRuleConditionType["AirlineRuleConditionTypeFareFamily"] = "AIRLINE_RULE_CONDITION_TYPE_FARE_FAMILY";
    AirlineRuleConditionType["AirlineRuleConditionTypeFareType"] = "AIRLINE_RULE_CONDITION_TYPE_FARE_TYPE";
    AirlineRuleConditionType["AirlineRuleConditionTypeFlightNumber"] = "AIRLINE_RULE_CONDITION_TYPE_FLIGHT_NUMBER";
    AirlineRuleConditionType["AirlineRuleConditionTypeHasCodeshare"] = "AIRLINE_RULE_CONDITION_TYPE_HAS_CODESHARE";
    AirlineRuleConditionType["AirlineRuleConditionTypeHasDomesticCodeshare"] = "AIRLINE_RULE_CONDITION_TYPE_HAS_DOMESTIC_CODESHARE";
    AirlineRuleConditionType["AirlineRuleConditionTypeHasInterline"] = "AIRLINE_RULE_CONDITION_TYPE_HAS_INTERLINE";
    AirlineRuleConditionType["AirlineRuleConditionTypeHasInternationalCodeshare"] = "AIRLINE_RULE_CONDITION_TYPE_HAS_INTERNATIONAL_CODESHARE";
    AirlineRuleConditionType["AirlineRuleConditionTypeInterline"] = "AIRLINE_RULE_CONDITION_TYPE_INTERLINE";
    AirlineRuleConditionType["AirlineRuleConditionTypeInternationalOnline"] = "AIRLINE_RULE_CONDITION_TYPE_INTERNATIONAL_ONLINE";
    AirlineRuleConditionType["AirlineRuleConditionTypeOnline"] = "AIRLINE_RULE_CONDITION_TYPE_ONLINE";
    AirlineRuleConditionType["AirlineRuleConditionTypeOriginAirport"] = "AIRLINE_RULE_CONDITION_TYPE_ORIGIN_AIRPORT";
    AirlineRuleConditionType["AirlineRuleConditionTypeOriginCity"] = "AIRLINE_RULE_CONDITION_TYPE_ORIGIN_CITY";
    AirlineRuleConditionType["AirlineRuleConditionTypeOriginContinent"] = "AIRLINE_RULE_CONDITION_TYPE_ORIGIN_CONTINENT";
    AirlineRuleConditionType["AirlineRuleConditionTypeOriginCountry"] = "AIRLINE_RULE_CONDITION_TYPE_ORIGIN_COUNTRY";
    AirlineRuleConditionType["AirlineRuleConditionTypeReturnDate"] = "AIRLINE_RULE_CONDITION_TYPE_RETURN_DATE";
    AirlineRuleConditionType["AirlineRuleConditionTypeSaleDate"] = "AIRLINE_RULE_CONDITION_TYPE_SALE_DATE";
})(AirlineRuleConditionType || (AirlineRuleConditionType = {}));
/** The Airline Rule Condition Value Type */
export var AirlineRuleConditionValueType;
(function (AirlineRuleConditionValueType) {
    AirlineRuleConditionValueType["AirlineRuleConditionValueTypeBoolean"] = "AIRLINE_RULE_CONDITION_VALUE_TYPE_BOOLEAN";
    AirlineRuleConditionValueType["AirlineRuleConditionValueTypeCabinCode"] = "AIRLINE_RULE_CONDITION_VALUE_TYPE_CABIN_CODE";
    AirlineRuleConditionValueType["AirlineRuleConditionValueTypeDate"] = "AIRLINE_RULE_CONDITION_VALUE_TYPE_DATE";
    AirlineRuleConditionValueType["AirlineRuleConditionValueTypeDateRange"] = "AIRLINE_RULE_CONDITION_VALUE_TYPE_DATE_RANGE";
    AirlineRuleConditionValueType["AirlineRuleConditionValueTypeDateTime"] = "AIRLINE_RULE_CONDITION_VALUE_TYPE_DATE_TIME";
    AirlineRuleConditionValueType["AirlineRuleConditionValueTypeDateTimeRange"] = "AIRLINE_RULE_CONDITION_VALUE_TYPE_DATE_TIME_RANGE";
    AirlineRuleConditionValueType["AirlineRuleConditionValueTypeFareTypeCode"] = "AIRLINE_RULE_CONDITION_VALUE_TYPE_FARE_TYPE_CODE";
    AirlineRuleConditionValueType["AirlineRuleConditionValueTypeIataAirlineCode"] = "AIRLINE_RULE_CONDITION_VALUE_TYPE_IATA_AIRLINE_CODE";
    AirlineRuleConditionValueType["AirlineRuleConditionValueTypeIataAirportCode"] = "AIRLINE_RULE_CONDITION_VALUE_TYPE_IATA_AIRPORT_CODE";
    AirlineRuleConditionValueType["AirlineRuleConditionValueTypeIataCityCode"] = "AIRLINE_RULE_CONDITION_VALUE_TYPE_IATA_CITY_CODE";
    AirlineRuleConditionValueType["AirlineRuleConditionValueTypeIataContinentCode"] = "AIRLINE_RULE_CONDITION_VALUE_TYPE_IATA_CONTINENT_CODE";
    AirlineRuleConditionValueType["AirlineRuleConditionValueTypeIataCountryCode"] = "AIRLINE_RULE_CONDITION_VALUE_TYPE_IATA_COUNTRY_CODE";
    AirlineRuleConditionValueType["AirlineRuleConditionValueTypeInt"] = "AIRLINE_RULE_CONDITION_VALUE_TYPE_INT";
    AirlineRuleConditionValueType["AirlineRuleConditionValueTypeIntRange"] = "AIRLINE_RULE_CONDITION_VALUE_TYPE_INT_RANGE";
    AirlineRuleConditionValueType["AirlineRuleConditionValueTypeOneLetter"] = "AIRLINE_RULE_CONDITION_VALUE_TYPE_ONE_LETTER";
    AirlineRuleConditionValueType["AirlineRuleConditionValueTypeString"] = "AIRLINE_RULE_CONDITION_VALUE_TYPE_STRING";
})(AirlineRuleConditionValueType || (AirlineRuleConditionValueType = {}));
/** The Airline Rules Setting Activity Type */
export var AirlineRulesSettingActivityType;
(function (AirlineRulesSettingActivityType) {
    AirlineRulesSettingActivityType["AirlineRulesSettingActivityTypeCreated"] = "AIRLINE_RULES_SETTING_ACTIVITY_TYPE_CREATED";
    AirlineRulesSettingActivityType["AirlineRulesSettingActivityTypeDeleted"] = "AIRLINE_RULES_SETTING_ACTIVITY_TYPE_DELETED";
    AirlineRulesSettingActivityType["AirlineRulesSettingActivityTypeDisabled"] = "AIRLINE_RULES_SETTING_ACTIVITY_TYPE_DISABLED";
    AirlineRulesSettingActivityType["AirlineRulesSettingActivityTypeEdited"] = "AIRLINE_RULES_SETTING_ACTIVITY_TYPE_EDITED";
    AirlineRulesSettingActivityType["AirlineRulesSettingActivityTypeEnabled"] = "AIRLINE_RULES_SETTING_ACTIVITY_TYPE_ENABLED";
    AirlineRulesSettingActivityType["AirlineRulesSettingActivityTypeUnspecified"] = "AIRLINE_RULES_SETTING_ACTIVITY_TYPE_UNSPECIFIED";
})(AirlineRulesSettingActivityType || (AirlineRulesSettingActivityType = {}));
/** The application type */
export var ApplicationType;
(function (ApplicationType) {
    ApplicationType["ApplicationTypeApp"] = "APPLICATION_TYPE_APP";
    ApplicationType["ApplicationTypeCockpit"] = "APPLICATION_TYPE_COCKPIT";
    ApplicationType["ApplicationTypeControlPanel"] = "APPLICATION_TYPE_CONTROL_PANEL";
    ApplicationType["ApplicationTypeUnspecified"] = "APPLICATION_TYPE_UNSPECIFIED";
})(ApplicationType || (ApplicationType = {}));
/** Baggage */
export var Baggage;
(function (Baggage) {
    Baggage["Baggage_1Piece"] = "BAGGAGE_1_PIECE";
    Baggage["Baggage_1Piece_23Kg"] = "BAGGAGE_1_PIECE_23KG";
    Baggage["Baggage_1Piece_32Kg"] = "BAGGAGE_1_PIECE_32KG";
    Baggage["Baggage_2Piece"] = "BAGGAGE_2_PIECE";
    Baggage["Baggage_2Piece_23Kg"] = "BAGGAGE_2_PIECE_23KG";
    Baggage["Baggage_2Piece_32Kg"] = "BAGGAGE_2_PIECE_32KG";
    Baggage["Baggage_3Piece"] = "BAGGAGE_3_PIECE";
    Baggage["Baggage_3Piece_23Kg"] = "BAGGAGE_3_PIECE_23KG";
    Baggage["Baggage_3Piece_32Kg"] = "BAGGAGE_3_PIECE_32KG";
    Baggage["BaggageNo"] = "BAGGAGE_NO";
    Baggage["BaggageUnspecified"] = "BAGGAGE_UNSPECIFIED";
})(Baggage || (Baggage = {}));
/** The contact type AGENT|AGENCY */
export var BookingContactType;
(function (BookingContactType) {
    BookingContactType["BookingContactTypeAgency"] = "BOOKING_CONTACT_TYPE_AGENCY";
    BookingContactType["BookingContactTypeAgent"] = "BOOKING_CONTACT_TYPE_AGENT";
    BookingContactType["BookingContactTypeUnspecified"] = "BOOKING_CONTACT_TYPE_UNSPECIFIED";
})(BookingContactType || (BookingContactType = {}));
/** The Branch Activity Type */
export var BranchActivityType;
(function (BranchActivityType) {
    BranchActivityType["BranchActivityTypeCreated"] = "BRANCH_ACTIVITY_TYPE_CREATED";
    BranchActivityType["BranchActivityTypeDeleted"] = "BRANCH_ACTIVITY_TYPE_DELETED";
    BranchActivityType["BranchActivityTypeDisabled"] = "BRANCH_ACTIVITY_TYPE_DISABLED";
    BranchActivityType["BranchActivityTypeEdited"] = "BRANCH_ACTIVITY_TYPE_EDITED";
    BranchActivityType["BranchActivityTypeEnabled"] = "BRANCH_ACTIVITY_TYPE_ENABLED";
    BranchActivityType["BranchActivityTypeUnspecified"] = "BRANCH_ACTIVITY_TYPE_UNSPECIFIED";
})(BranchActivityType || (BranchActivityType = {}));
/** Cabin */
export var Cabin;
(function (Cabin) {
    Cabin["CabinBusiness"] = "CABIN_BUSINESS";
    Cabin["CabinEconomy"] = "CABIN_ECONOMY";
    Cabin["CabinFirst"] = "CABIN_FIRST";
    Cabin["CabinPremium"] = "CABIN_PREMIUM";
    Cabin["CabinUnspecified"] = "CABIN_UNSPECIFIED";
})(Cabin || (Cabin = {}));
/** The card brand */
export var CardBrand;
(function (CardBrand) {
    CardBrand["CardBrandAmex"] = "CARD_BRAND_AMEX";
    CardBrand["CardBrandDiners"] = "CARD_BRAND_DINERS";
    CardBrand["CardBrandDiscover"] = "CARD_BRAND_DISCOVER";
    CardBrand["CardBrandElo"] = "CARD_BRAND_ELO";
    CardBrand["CardBrandHipercard"] = "CARD_BRAND_HIPERCARD";
    CardBrand["CardBrandMastercard"] = "CARD_BRAND_MASTERCARD";
    CardBrand["CardBrandUnspecified"] = "CARD_BRAND_UNSPECIFIED";
    CardBrand["CardBrandVisa"] = "CARD_BRAND_VISA";
})(CardBrand || (CardBrand = {}));
/** The commission module activity Type UNSPECIFIED|CREATED|EDITED|DELETED */
export var CommissionModuleActivityType;
(function (CommissionModuleActivityType) {
    CommissionModuleActivityType["CommissionModuleActivityTypeCreated"] = "COMMISSION_MODULE_ACTIVITY_TYPE_CREATED";
    CommissionModuleActivityType["CommissionModuleActivityTypeDeleted"] = "COMMISSION_MODULE_ACTIVITY_TYPE_DELETED";
    CommissionModuleActivityType["CommissionModuleActivityTypeEdited"] = "COMMISSION_MODULE_ACTIVITY_TYPE_EDITED";
    CommissionModuleActivityType["CommissionModuleActivityTypeUnspecified"] = "COMMISSION_MODULE_ACTIVITY_TYPE_UNSPECIFIED";
})(CommissionModuleActivityType || (CommissionModuleActivityType = {}));
/** The commission Type UNSPECIFIED|CONSOLID|AGENCY */
export var CommissionType;
(function (CommissionType) {
    CommissionType["CommissionTypeAgency"] = "COMMISSION_TYPE_AGENCY";
    CommissionType["CommissionTypeConsolid"] = "COMMISSION_TYPE_CONSOLID";
    CommissionType["CommissionTypeUnspecified"] = "COMMISSION_TYPE_UNSPECIFIED";
})(CommissionType || (CommissionType = {}));
/** The credit period type WEEK|MONTH */
export var CreditPeriodType;
(function (CreditPeriodType) {
    CreditPeriodType["CreditPeriodTypeMonth"] = "CREDIT_PERIOD_TYPE_MONTH";
    CreditPeriodType["CreditPeriodTypeUnspecified"] = "CREDIT_PERIOD_TYPE_UNSPECIFIED";
    CreditPeriodType["CreditPeriodTypeWeek"] = "CREDIT_PERIOD_TYPE_WEEK";
})(CreditPeriodType || (CreditPeriodType = {}));
/** The credit status type HAS_CREDIT|NO_CREDIT|NO_CREDIT_AND_BLOCKED */
export var CreditStatusType;
(function (CreditStatusType) {
    CreditStatusType["CreditStatusTypeHasCredit"] = "CREDIT_STATUS_TYPE_HAS_CREDIT";
    CreditStatusType["CreditStatusTypeNoCredit"] = "CREDIT_STATUS_TYPE_NO_CREDIT";
    CreditStatusType["CreditStatusTypeNoCreditAndBlocked"] = "CREDIT_STATUS_TYPE_NO_CREDIT_AND_BLOCKED";
    CreditStatusType["CreditStatusTypeUnspecified"] = "CREDIT_STATUS_TYPE_UNSPECIFIED";
})(CreditStatusType || (CreditStatusType = {}));
/** The fare type */
export var FareType;
(function (FareType) {
    FareType["FareTypeBoth"] = "FARE_TYPE_BOTH";
    FareType["FareTypePrivate"] = "FARE_TYPE_PRIVATE";
    FareType["FareTypePublic"] = "FARE_TYPE_PUBLIC";
    FareType["FareTypeUnspecified"] = "FARE_TYPE_UNSPECIFIED";
})(FareType || (FareType = {}));
/** The form of payments */
export var FopType;
(function (FopType) {
    FopType["FopTypeCreditcard"] = "FOP_TYPE_CREDITCARD";
    FopType["FopTypeInvoice"] = "FOP_TYPE_INVOICE";
    FopType["FopTypePix"] = "FOP_TYPE_PIX";
    FopType["FopTypeUnspecified"] = "FOP_TYPE_UNSPECIFIED";
})(FopType || (FopType = {}));
/** The gender type */
export var GenderType;
(function (GenderType) {
    GenderType["GenderTypeFemale"] = "GENDER_TYPE_FEMALE";
    GenderType["GenderTypeMale"] = "GENDER_TYPE_MALE";
    GenderType["GenderTypeUnspecified"] = "GENDER_TYPE_UNSPECIFIED";
})(GenderType || (GenderType = {}));
/** The leg status */
export var LegStatus;
(function (LegStatus) {
    LegStatus["LegStatusCanceled"] = "LEG_STATUS_CANCELED";
    LegStatus["LegStatusCheckedIn"] = "LEG_STATUS_CHECKED_IN";
    LegStatus["LegStatusConfirmed"] = "LEG_STATUS_CONFIRMED";
    LegStatus["LegStatusFlown"] = "LEG_STATUS_FLOWN";
    LegStatus["LegStatusFraud"] = "LEG_STATUS_FRAUD";
    LegStatus["LegStatusNoShow"] = "LEG_STATUS_NO_SHOW";
    LegStatus["LegStatusPendingConfirmation"] = "LEG_STATUS_PENDING_CONFIRMATION";
    LegStatus["LegStatusRefunded"] = "LEG_STATUS_REFUNDED";
    LegStatus["LegStatusReissued"] = "LEG_STATUS_REISSUED";
    LegStatus["LegStatusScheduleChange"] = "LEG_STATUS_SCHEDULE_CHANGE";
    LegStatus["LegStatusSuspended"] = "LEG_STATUS_SUSPENDED";
    LegStatus["LegStatusUnspecified"] = "LEG_STATUS_UNSPECIFIED";
    LegStatus["LegStatusVoid"] = "LEG_STATUS_VOID";
    LegStatus["LegStatusWaitList"] = "LEG_STATUS_WAIT_LIST";
})(LegStatus || (LegStatus = {}));
/** The markup module activity type */
export var MarkupModuleActivityType;
(function (MarkupModuleActivityType) {
    MarkupModuleActivityType["MarkupModuleActivityTypeCreated"] = "MARKUP_MODULE_ACTIVITY_TYPE_CREATED";
    MarkupModuleActivityType["MarkupModuleActivityTypeDeleted"] = "MARKUP_MODULE_ACTIVITY_TYPE_DELETED";
    MarkupModuleActivityType["MarkupModuleActivityTypeDisabled"] = "MARKUP_MODULE_ACTIVITY_TYPE_DISABLED";
    MarkupModuleActivityType["MarkupModuleActivityTypeEdited"] = "MARKUP_MODULE_ACTIVITY_TYPE_EDITED";
    MarkupModuleActivityType["MarkupModuleActivityTypeEnabled"] = "MARKUP_MODULE_ACTIVITY_TYPE_ENABLED";
    MarkupModuleActivityType["MarkupModuleActivityTypeUnspecified"] = "MARKUP_MODULE_ACTIVITY_TYPE_UNSPECIFIED";
})(MarkupModuleActivityType || (MarkupModuleActivityType = {}));
export var PassengerDocumentType;
(function (PassengerDocumentType) {
    PassengerDocumentType["Cpf"] = "CPF";
    PassengerDocumentType["Dni"] = "DNI";
    PassengerDocumentType["Passport"] = "PASSPORT";
    PassengerDocumentType["Rg"] = "RG";
    PassengerDocumentType["Rnm"] = "RNM";
    PassengerDocumentType["Unspecified"] = "UNSPECIFIED";
})(PassengerDocumentType || (PassengerDocumentType = {}));
/** The passenger profile activity type */
export var PassengerProfileActivityType;
(function (PassengerProfileActivityType) {
    PassengerProfileActivityType["PassengerProfileActivityTypeCreated"] = "PASSENGER_PROFILE_ACTIVITY_TYPE_CREATED";
    PassengerProfileActivityType["PassengerProfileActivityTypeDeleted"] = "PASSENGER_PROFILE_ACTIVITY_TYPE_DELETED";
    PassengerProfileActivityType["PassengerProfileActivityTypeDisabled"] = "PASSENGER_PROFILE_ACTIVITY_TYPE_DISABLED";
    PassengerProfileActivityType["PassengerProfileActivityTypeEdited"] = "PASSENGER_PROFILE_ACTIVITY_TYPE_EDITED";
    PassengerProfileActivityType["PassengerProfileActivityTypeEnabled"] = "PASSENGER_PROFILE_ACTIVITY_TYPE_ENABLED";
    PassengerProfileActivityType["PassengerProfileActivityTypeUnspecified"] = "PASSENGER_PROFILE_ACTIVITY_TYPE_UNSPECIFIED";
})(PassengerProfileActivityType || (PassengerProfileActivityType = {}));
export var PassengerProfileStatus;
(function (PassengerProfileStatus) {
    PassengerProfileStatus["PassengerProfileStatusDeleted"] = "PASSENGER_PROFILE_STATUS_DELETED";
    PassengerProfileStatus["PassengerProfileStatusDisabled"] = "PASSENGER_PROFILE_STATUS_DISABLED";
    PassengerProfileStatus["PassengerProfileStatusEnabled"] = "PASSENGER_PROFILE_STATUS_ENABLED";
    PassengerProfileStatus["PassengerProfileStatusUnspecified"] = "PASSENGER_PROFILE_STATUS_UNSPECIFIED";
})(PassengerProfileStatus || (PassengerProfileStatus = {}));
export var PaxType;
(function (PaxType) {
    PaxType["PaxTypeAdt"] = "PAX_TYPE_ADT";
    PaxType["PaxTypeChd"] = "PAX_TYPE_CHD";
    PaxType["PaxTypeInf"] = "PAX_TYPE_INF";
    PaxType["PaxTypeUnspecified"] = "PAX_TYPE_UNSPECIFIED";
})(PaxType || (PaxType = {}));
/** The price breakdown type */
export var PriceBreakdownType;
(function (PriceBreakdownType) {
    PriceBreakdownType["PriceBreakdownTypeFull"] = "PRICE_BREAKDOWN_TYPE_FULL";
    PriceBreakdownType["PriceBreakdownTypeNone"] = "PRICE_BREAKDOWN_TYPE_NONE";
    PriceBreakdownType["PriceBreakdownTypeTotals"] = "PRICE_BREAKDOWN_TYPE_TOTALS";
    PriceBreakdownType["PriceBreakdownTypeUnspecified"] = "PRICE_BREAKDOWN_TYPE_UNSPECIFIED";
})(PriceBreakdownType || (PriceBreakdownType = {}));
/** The provider type */
export var ProviderType;
(function (ProviderType) {
    ProviderType["ProviderTypeAmerican"] = "PROVIDER_TYPE_AMERICAN";
    ProviderType["ProviderTypeAzul"] = "PROVIDER_TYPE_AZUL";
    ProviderType["ProviderTypeElatam"] = "PROVIDER_TYPE_ELATAM";
    ProviderType["ProviderTypeFarelogix"] = "PROVIDER_TYPE_FARELOGIX";
    ProviderType["ProviderTypeGol"] = "PROVIDER_TYPE_GOL";
    ProviderType["ProviderTypeLatam"] = "PROVIDER_TYPE_LATAM";
    ProviderType["ProviderTypeSabre"] = "PROVIDER_TYPE_SABRE";
    ProviderType["ProviderTypeUnspecified"] = "PROVIDER_TYPE_UNSPECIFIED";
})(ProviderType || (ProviderType = {}));
/** The role permission */
export var RolePermission;
(function (RolePermission) {
    RolePermission["RolePermissionCheckCredit"] = "ROLE_PERMISSION_CHECK_CREDIT";
    RolePermission["RolePermissionCreateAgency"] = "ROLE_PERMISSION_CREATE_AGENCY";
    RolePermission["RolePermissionCreateAirline"] = "ROLE_PERMISSION_CREATE_AIRLINE";
    RolePermission["RolePermissionCreateAirlineRule"] = "ROLE_PERMISSION_CREATE_AIRLINE_RULE";
    RolePermission["RolePermissionCreateAny"] = "ROLE_PERMISSION_CREATE_ANY";
    RolePermission["RolePermissionCreateAvatarAgency"] = "ROLE_PERMISSION_CREATE_AVATAR_AGENCY";
    RolePermission["RolePermissionCreateAvatarAny"] = "ROLE_PERMISSION_CREATE_AVATAR_ANY";
    RolePermission["RolePermissionCreateBookingAgency"] = "ROLE_PERMISSION_CREATE_BOOKING_AGENCY";
    RolePermission["RolePermissionCreateBookingAny"] = "ROLE_PERMISSION_CREATE_BOOKING_ANY";
    RolePermission["RolePermissionCreateCancelOrderAgency"] = "ROLE_PERMISSION_CREATE_CANCEL_ORDER_AGENCY";
    RolePermission["RolePermissionCreateCancelOrderAny"] = "ROLE_PERMISSION_CREATE_CANCEL_ORDER_ANY";
    RolePermission["RolePermissionCreateCancelOrderOwn"] = "ROLE_PERMISSION_CREATE_CANCEL_ORDER_OWN";
    RolePermission["RolePermissionCreateCredential"] = "ROLE_PERMISSION_CREATE_CREDENTIAL";
    RolePermission["RolePermissionCreateLocation"] = "ROLE_PERMISSION_CREATE_LOCATION";
    RolePermission["RolePermissionCreateOffer"] = "ROLE_PERMISSION_CREATE_OFFER";
    RolePermission["RolePermissionCreateOrderAgency"] = "ROLE_PERMISSION_CREATE_ORDER_AGENCY";
    RolePermission["RolePermissionCreateOrderAny"] = "ROLE_PERMISSION_CREATE_ORDER_ANY";
    RolePermission["RolePermissionCreatePassengerProfileAgency"] = "ROLE_PERMISSION_CREATE_PASSENGER_PROFILE_AGENCY";
    RolePermission["RolePermissionCreatePassengerProfileAny"] = "ROLE_PERMISSION_CREATE_PASSENGER_PROFILE_ANY";
    RolePermission["RolePermissionCreateQuote"] = "ROLE_PERMISSION_CREATE_QUOTE";
    RolePermission["RolePermissionCreateTicketAgency"] = "ROLE_PERMISSION_CREATE_TICKET_AGENCY";
    RolePermission["RolePermissionCreateTicketAny"] = "ROLE_PERMISSION_CREATE_TICKET_ANY";
    RolePermission["RolePermissionCreateUserAgency"] = "ROLE_PERMISSION_CREATE_USER_AGENCY";
    RolePermission["RolePermissionCreateUserAny"] = "ROLE_PERMISSION_CREATE_USER_ANY";
    RolePermission["RolePermissionDeleteAgency"] = "ROLE_PERMISSION_DELETE_AGENCY";
    RolePermission["RolePermissionDeleteAirline"] = "ROLE_PERMISSION_DELETE_AIRLINE";
    RolePermission["RolePermissionDeleteAirlineRule"] = "ROLE_PERMISSION_DELETE_AIRLINE_RULE";
    RolePermission["RolePermissionDeleteAny"] = "ROLE_PERMISSION_DELETE_ANY";
    RolePermission["RolePermissionDeleteAvatarAgency"] = "ROLE_PERMISSION_DELETE_AVATAR_AGENCY";
    RolePermission["RolePermissionDeleteAvatarAny"] = "ROLE_PERMISSION_DELETE_AVATAR_ANY";
    RolePermission["RolePermissionDeleteAvatarOwn"] = "ROLE_PERMISSION_DELETE_AVATAR_OWN";
    RolePermission["RolePermissionDeleteBookingAgency"] = "ROLE_PERMISSION_DELETE_BOOKING_AGENCY";
    RolePermission["RolePermissionDeleteBookingAny"] = "ROLE_PERMISSION_DELETE_BOOKING_ANY";
    RolePermission["RolePermissionDeleteBookingOwn"] = "ROLE_PERMISSION_DELETE_BOOKING_OWN";
    RolePermission["RolePermissionDeleteCredential"] = "ROLE_PERMISSION_DELETE_CREDENTIAL";
    RolePermission["RolePermissionDeleteLocation"] = "ROLE_PERMISSION_DELETE_LOCATION";
    RolePermission["RolePermissionDeleteOffer"] = "ROLE_PERMISSION_DELETE_OFFER";
    RolePermission["RolePermissionDeleteOrderAgency"] = "ROLE_PERMISSION_DELETE_ORDER_AGENCY";
    RolePermission["RolePermissionDeleteOrderAny"] = "ROLE_PERMISSION_DELETE_ORDER_ANY";
    RolePermission["RolePermissionDeleteOrderOwn"] = "ROLE_PERMISSION_DELETE_ORDER_OWN";
    RolePermission["RolePermissionDeletePassengerProfileAgency"] = "ROLE_PERMISSION_DELETE_PASSENGER_PROFILE_AGENCY";
    RolePermission["RolePermissionDeletePassengerProfileAny"] = "ROLE_PERMISSION_DELETE_PASSENGER_PROFILE_ANY";
    RolePermission["RolePermissionDeletePassengerProfileOwn"] = "ROLE_PERMISSION_DELETE_PASSENGER_PROFILE_OWN";
    RolePermission["RolePermissionDeleteQuote"] = "ROLE_PERMISSION_DELETE_QUOTE";
    RolePermission["RolePermissionDeleteTicketAgency"] = "ROLE_PERMISSION_DELETE_TICKET_AGENCY";
    RolePermission["RolePermissionDeleteTicketAny"] = "ROLE_PERMISSION_DELETE_TICKET_ANY";
    RolePermission["RolePermissionDeleteTicketOwn"] = "ROLE_PERMISSION_DELETE_TICKET_OWN";
    RolePermission["RolePermissionDeleteUserAgency"] = "ROLE_PERMISSION_DELETE_USER_AGENCY";
    RolePermission["RolePermissionDeleteUserAny"] = "ROLE_PERMISSION_DELETE_USER_ANY";
    RolePermission["RolePermissionDeleteUserOwn"] = "ROLE_PERMISSION_DELETE_USER_OWN";
    RolePermission["RolePermissionReadAgency"] = "ROLE_PERMISSION_READ_AGENCY";
    RolePermission["RolePermissionReadAgencySettings"] = "ROLE_PERMISSION_READ_AGENCY_SETTINGS";
    RolePermission["RolePermissionReadAirline"] = "ROLE_PERMISSION_READ_AIRLINE";
    RolePermission["RolePermissionReadAirlineRule"] = "ROLE_PERMISSION_READ_AIRLINE_RULE";
    RolePermission["RolePermissionReadAny"] = "ROLE_PERMISSION_READ_ANY";
    RolePermission["RolePermissionReadAvatarAgency"] = "ROLE_PERMISSION_READ_AVATAR_AGENCY";
    RolePermission["RolePermissionReadAvatarAny"] = "ROLE_PERMISSION_READ_AVATAR_ANY";
    RolePermission["RolePermissionReadAvatarOwn"] = "ROLE_PERMISSION_READ_AVATAR_OWN";
    RolePermission["RolePermissionReadBookingAgency"] = "ROLE_PERMISSION_READ_BOOKING_AGENCY";
    RolePermission["RolePermissionReadBookingAny"] = "ROLE_PERMISSION_READ_BOOKING_ANY";
    RolePermission["RolePermissionReadBookingOwn"] = "ROLE_PERMISSION_READ_BOOKING_OWN";
    RolePermission["RolePermissionReadCancelOrderAgency"] = "ROLE_PERMISSION_READ_CANCEL_ORDER_AGENCY";
    RolePermission["RolePermissionReadCancelOrderAny"] = "ROLE_PERMISSION_READ_CANCEL_ORDER_ANY";
    RolePermission["RolePermissionReadCancelOrderOwn"] = "ROLE_PERMISSION_READ_CANCEL_ORDER_OWN";
    RolePermission["RolePermissionReadCompany"] = "ROLE_PERMISSION_READ_COMPANY";
    RolePermission["RolePermissionReadCredential"] = "ROLE_PERMISSION_READ_CREDENTIAL";
    RolePermission["RolePermissionReadCredit"] = "ROLE_PERMISSION_READ_CREDIT";
    RolePermission["RolePermissionReadLocation"] = "ROLE_PERMISSION_READ_LOCATION";
    RolePermission["RolePermissionReadOffer"] = "ROLE_PERMISSION_READ_OFFER";
    RolePermission["RolePermissionReadOrderAgency"] = "ROLE_PERMISSION_READ_ORDER_AGENCY";
    RolePermission["RolePermissionReadOrderAny"] = "ROLE_PERMISSION_READ_ORDER_ANY";
    RolePermission["RolePermissionReadOrderOwn"] = "ROLE_PERMISSION_READ_ORDER_OWN";
    RolePermission["RolePermissionReadPassengerProfileAgency"] = "ROLE_PERMISSION_READ_PASSENGER_PROFILE_AGENCY";
    RolePermission["RolePermissionReadPassengerProfileAny"] = "ROLE_PERMISSION_READ_PASSENGER_PROFILE_ANY";
    RolePermission["RolePermissionReadPassengerProfileOwn"] = "ROLE_PERMISSION_READ_PASSENGER_PROFILE_OWN";
    RolePermission["RolePermissionReadQuote"] = "ROLE_PERMISSION_READ_QUOTE";
    RolePermission["RolePermissionReadTicketAgency"] = "ROLE_PERMISSION_READ_TICKET_AGENCY";
    RolePermission["RolePermissionReadTicketAny"] = "ROLE_PERMISSION_READ_TICKET_ANY";
    RolePermission["RolePermissionReadTicketOwn"] = "ROLE_PERMISSION_READ_TICKET_OWN";
    RolePermission["RolePermissionReadUserAgency"] = "ROLE_PERMISSION_READ_USER_AGENCY";
    RolePermission["RolePermissionReadUserAny"] = "ROLE_PERMISSION_READ_USER_ANY";
    RolePermission["RolePermissionReadUserOwn"] = "ROLE_PERMISSION_READ_USER_OWN";
    RolePermission["RolePermissionUnspecified"] = "ROLE_PERMISSION_UNSPECIFIED";
    RolePermission["RolePermissionUpdateAgency"] = "ROLE_PERMISSION_UPDATE_AGENCY";
    RolePermission["RolePermissionUpdateAirline"] = "ROLE_PERMISSION_UPDATE_AIRLINE";
    RolePermission["RolePermissionUpdateAirlineRule"] = "ROLE_PERMISSION_UPDATE_AIRLINE_RULE";
    RolePermission["RolePermissionUpdateAny"] = "ROLE_PERMISSION_UPDATE_ANY";
    RolePermission["RolePermissionUpdateAvatarAgency"] = "ROLE_PERMISSION_UPDATE_AVATAR_AGENCY";
    RolePermission["RolePermissionUpdateAvatarAny"] = "ROLE_PERMISSION_UPDATE_AVATAR_ANY";
    RolePermission["RolePermissionUpdateAvatarOwn"] = "ROLE_PERMISSION_UPDATE_AVATAR_OWN";
    RolePermission["RolePermissionUpdateBookingAgency"] = "ROLE_PERMISSION_UPDATE_BOOKING_AGENCY";
    RolePermission["RolePermissionUpdateBookingAny"] = "ROLE_PERMISSION_UPDATE_BOOKING_ANY";
    RolePermission["RolePermissionUpdateBookingOwn"] = "ROLE_PERMISSION_UPDATE_BOOKING_OWN";
    RolePermission["RolePermissionUpdateCredential"] = "ROLE_PERMISSION_UPDATE_CREDENTIAL";
    RolePermission["RolePermissionUpdateCredit"] = "ROLE_PERMISSION_UPDATE_CREDIT";
    RolePermission["RolePermissionUpdateLocation"] = "ROLE_PERMISSION_UPDATE_LOCATION";
    RolePermission["RolePermissionUpdateOffer"] = "ROLE_PERMISSION_UPDATE_OFFER";
    RolePermission["RolePermissionUpdateOrderAgency"] = "ROLE_PERMISSION_UPDATE_ORDER_AGENCY";
    RolePermission["RolePermissionUpdateOrderAny"] = "ROLE_PERMISSION_UPDATE_ORDER_ANY";
    RolePermission["RolePermissionUpdateOrderOwn"] = "ROLE_PERMISSION_UPDATE_ORDER_OWN";
    RolePermission["RolePermissionUpdatePassengerProfileAgency"] = "ROLE_PERMISSION_UPDATE_PASSENGER_PROFILE_AGENCY";
    RolePermission["RolePermissionUpdatePassengerProfileAny"] = "ROLE_PERMISSION_UPDATE_PASSENGER_PROFILE_ANY";
    RolePermission["RolePermissionUpdatePassengerProfileOwn"] = "ROLE_PERMISSION_UPDATE_PASSENGER_PROFILE_OWN";
    RolePermission["RolePermissionUpdateQuote"] = "ROLE_PERMISSION_UPDATE_QUOTE";
    RolePermission["RolePermissionUpdateTicketAgency"] = "ROLE_PERMISSION_UPDATE_TICKET_AGENCY";
    RolePermission["RolePermissionUpdateTicketAny"] = "ROLE_PERMISSION_UPDATE_TICKET_ANY";
    RolePermission["RolePermissionUpdateTicketOwn"] = "ROLE_PERMISSION_UPDATE_TICKET_OWN";
    RolePermission["RolePermissionUpdateUserAgency"] = "ROLE_PERMISSION_UPDATE_USER_AGENCY";
    RolePermission["RolePermissionUpdateUserAny"] = "ROLE_PERMISSION_UPDATE_USER_ANY";
    RolePermission["RolePermissionUpdateUserOwn"] = "ROLE_PERMISSION_UPDATE_USER_OWN";
})(RolePermission || (RolePermission = {}));
/** The role type */
export var RoleType;
(function (RoleType) {
    RoleType["RoleTypeAdmin"] = "ROLE_TYPE_ADMIN";
    RoleType["RoleTypeAgencyAdmin"] = "ROLE_TYPE_AGENCY_ADMIN";
    RoleType["RoleTypeAgent"] = "ROLE_TYPE_AGENT";
    RoleType["RoleTypeConsolidatorUser"] = "ROLE_TYPE_CONSOLIDATOR_USER";
    RoleType["RoleTypeIntegrator"] = "ROLE_TYPE_INTEGRATOR";
    RoleType["RoleTypeUnspecified"] = "ROLE_TYPE_UNSPECIFIED";
})(RoleType || (RoleType = {}));
/** The search module activity type */
export var SearchModuleActivityType;
(function (SearchModuleActivityType) {
    SearchModuleActivityType["SearchModuleActivityTypeCreated"] = "SEARCH_MODULE_ACTIVITY_TYPE_CREATED";
    SearchModuleActivityType["SearchModuleActivityTypeDeleted"] = "SEARCH_MODULE_ACTIVITY_TYPE_DELETED";
    SearchModuleActivityType["SearchModuleActivityTypeDisabled"] = "SEARCH_MODULE_ACTIVITY_TYPE_DISABLED";
    SearchModuleActivityType["SearchModuleActivityTypeEdited"] = "SEARCH_MODULE_ACTIVITY_TYPE_EDITED";
    SearchModuleActivityType["SearchModuleActivityTypeEnabled"] = "SEARCH_MODULE_ACTIVITY_TYPE_ENABLED";
    SearchModuleActivityType["SearchModuleActivityTypeUnspecified"] = "SEARCH_MODULE_ACTIVITY_TYPE_UNSPECIFIED";
})(SearchModuleActivityType || (SearchModuleActivityType = {}));
/** The search module condition operator type */
export var SearchModuleConditionOperatorType;
(function (SearchModuleConditionOperatorType) {
    SearchModuleConditionOperatorType["SearchModuleConditionOperatorTypeAfter"] = "SEARCH_MODULE_CONDITION_OPERATOR_TYPE_AFTER";
    SearchModuleConditionOperatorType["SearchModuleConditionOperatorTypeBefore"] = "SEARCH_MODULE_CONDITION_OPERATOR_TYPE_BEFORE";
    SearchModuleConditionOperatorType["SearchModuleConditionOperatorTypeBetween"] = "SEARCH_MODULE_CONDITION_OPERATOR_TYPE_BETWEEN";
    SearchModuleConditionOperatorType["SearchModuleConditionOperatorTypeEqual"] = "SEARCH_MODULE_CONDITION_OPERATOR_TYPE_EQUAL";
    SearchModuleConditionOperatorType["SearchModuleConditionOperatorTypeGrater"] = "SEARCH_MODULE_CONDITION_OPERATOR_TYPE_GRATER";
    SearchModuleConditionOperatorType["SearchModuleConditionOperatorTypeLesser"] = "SEARCH_MODULE_CONDITION_OPERATOR_TYPE_LESSER";
    SearchModuleConditionOperatorType["SearchModuleConditionOperatorTypeNotEqual"] = "SEARCH_MODULE_CONDITION_OPERATOR_TYPE_NOT_EQUAL";
})(SearchModuleConditionOperatorType || (SearchModuleConditionOperatorType = {}));
/** The search module condition type */
export var SearchModuleConditionType;
(function (SearchModuleConditionType) {
    SearchModuleConditionType["SearchModuleConditionTypeAdults"] = "SEARCH_MODULE_CONDITION_TYPE_ADULTS";
    SearchModuleConditionType["SearchModuleConditionTypeChildren"] = "SEARCH_MODULE_CONDITION_TYPE_CHILDREN";
    SearchModuleConditionType["SearchModuleConditionTypeDepartureDate"] = "SEARCH_MODULE_CONDITION_TYPE_DEPARTURE_DATE";
    SearchModuleConditionType["SearchModuleConditionTypeDestinationAirport"] = "SEARCH_MODULE_CONDITION_TYPE_DESTINATION_AIRPORT";
    SearchModuleConditionType["SearchModuleConditionTypeDestinationCity"] = "SEARCH_MODULE_CONDITION_TYPE_DESTINATION_CITY";
    SearchModuleConditionType["SearchModuleConditionTypeDestinationContinent"] = "SEARCH_MODULE_CONDITION_TYPE_DESTINATION_CONTINENT";
    SearchModuleConditionType["SearchModuleConditionTypeDestinationCountry"] = "SEARCH_MODULE_CONDITION_TYPE_DESTINATION_COUNTRY";
    SearchModuleConditionType["SearchModuleConditionTypeDomestic"] = "SEARCH_MODULE_CONDITION_TYPE_DOMESTIC";
    SearchModuleConditionType["SearchModuleConditionTypeInfants"] = "SEARCH_MODULE_CONDITION_TYPE_INFANTS";
    SearchModuleConditionType["SearchModuleConditionTypeOriginAirport"] = "SEARCH_MODULE_CONDITION_TYPE_ORIGIN_AIRPORT";
    SearchModuleConditionType["SearchModuleConditionTypeOriginCity"] = "SEARCH_MODULE_CONDITION_TYPE_ORIGIN_CITY";
    SearchModuleConditionType["SearchModuleConditionTypeOriginContinent"] = "SEARCH_MODULE_CONDITION_TYPE_ORIGIN_CONTINENT";
    SearchModuleConditionType["SearchModuleConditionTypeOriginCountry"] = "SEARCH_MODULE_CONDITION_TYPE_ORIGIN_COUNTRY";
    SearchModuleConditionType["SearchModuleConditionTypeReturnDate"] = "SEARCH_MODULE_CONDITION_TYPE_RETURN_DATE";
})(SearchModuleConditionType || (SearchModuleConditionType = {}));
/** The search module condition value type */
export var SearchModuleConditionValueType;
(function (SearchModuleConditionValueType) {
    SearchModuleConditionValueType["SearchModuleConditionValueTypeBoolean"] = "SEARCH_MODULE_CONDITION_VALUE_TYPE_BOOLEAN";
    SearchModuleConditionValueType["SearchModuleConditionValueTypeDate"] = "SEARCH_MODULE_CONDITION_VALUE_TYPE_DATE";
    SearchModuleConditionValueType["SearchModuleConditionValueTypeDateRange"] = "SEARCH_MODULE_CONDITION_VALUE_TYPE_DATE_RANGE";
    SearchModuleConditionValueType["SearchModuleConditionValueTypeDateTime"] = "SEARCH_MODULE_CONDITION_VALUE_TYPE_DATE_TIME";
    SearchModuleConditionValueType["SearchModuleConditionValueTypeDateTimeRange"] = "SEARCH_MODULE_CONDITION_VALUE_TYPE_DATE_TIME_RANGE";
    SearchModuleConditionValueType["SearchModuleConditionValueTypeIataAirportCode"] = "SEARCH_MODULE_CONDITION_VALUE_TYPE_IATA_AIRPORT_CODE";
    SearchModuleConditionValueType["SearchModuleConditionValueTypeIataCityCode"] = "SEARCH_MODULE_CONDITION_VALUE_TYPE_IATA_CITY_CODE";
    SearchModuleConditionValueType["SearchModuleConditionValueTypeIataContinentCode"] = "SEARCH_MODULE_CONDITION_VALUE_TYPE_IATA_CONTINENT_CODE";
    SearchModuleConditionValueType["SearchModuleConditionValueTypeIataCountryCode"] = "SEARCH_MODULE_CONDITION_VALUE_TYPE_IATA_COUNTRY_CODE";
    SearchModuleConditionValueType["SearchModuleConditionValueTypeInt"] = "SEARCH_MODULE_CONDITION_VALUE_TYPE_INT";
    SearchModuleConditionValueType["SearchModuleConditionValueTypeIntRange"] = "SEARCH_MODULE_CONDITION_VALUE_TYPE_INT_RANGE";
    SearchModuleConditionValueType["SearchModuleConditionValueTypeString"] = "SEARCH_MODULE_CONDITION_VALUE_TYPE_STRING";
})(SearchModuleConditionValueType || (SearchModuleConditionValueType = {}));
/** The search module rule activity type */
export var SearchModuleRuleActivityType;
(function (SearchModuleRuleActivityType) {
    SearchModuleRuleActivityType["SearchModuleRuleActivityTypeCreated"] = "SEARCH_MODULE_RULE_ACTIVITY_TYPE_CREATED";
    SearchModuleRuleActivityType["SearchModuleRuleActivityTypeDeleted"] = "SEARCH_MODULE_RULE_ACTIVITY_TYPE_DELETED";
    SearchModuleRuleActivityType["SearchModuleRuleActivityTypeDisabled"] = "SEARCH_MODULE_RULE_ACTIVITY_TYPE_DISABLED";
    SearchModuleRuleActivityType["SearchModuleRuleActivityTypeEdited"] = "SEARCH_MODULE_RULE_ACTIVITY_TYPE_EDITED";
    SearchModuleRuleActivityType["SearchModuleRuleActivityTypeEnabled"] = "SEARCH_MODULE_RULE_ACTIVITY_TYPE_ENABLED";
    SearchModuleRuleActivityType["SearchModuleRuleActivityTypeUnspecified"] = "SEARCH_MODULE_RULE_ACTIVITY_TYPE_UNSPECIFIED";
})(SearchModuleRuleActivityType || (SearchModuleRuleActivityType = {}));
/** The selection Type UNSPECIFIED|USER|SYSTEM */
export var SelectionCriteria;
(function (SelectionCriteria) {
    SelectionCriteria["SelectionCriteriaSystem"] = "SELECTION_CRITERIA_SYSTEM";
    SelectionCriteria["SelectionCriteriaUnspecified"] = "SELECTION_CRITERIA_UNSPECIFIED";
    SelectionCriteria["SelectionCriteriaUser"] = "SELECTION_CRITERIA_USER";
})(SelectionCriteria || (SelectionCriteria = {}));
/** The ticket status UNSPECIFIED|OPEN|VOID */
export var TicketStatus;
(function (TicketStatus) {
    TicketStatus["TicketStatusExchange"] = "TICKET_STATUS_EXCHANGE";
    TicketStatus["TicketStatusOpen"] = "TICKET_STATUS_OPEN";
    TicketStatus["TicketStatusUnspecified"] = "TICKET_STATUS_UNSPECIFIED";
    TicketStatus["TicketStatusVoid"] = "TICKET_STATUS_VOID";
})(TicketStatus || (TicketStatus = {}));
/** The ticket type EMD|TICKET */
export var TicketType;
(function (TicketType) {
    TicketType["TicketTypeAncillary"] = "TICKET_TYPE_ANCILLARY";
    TicketType["TicketTypeEmd"] = "TICKET_TYPE_EMD";
    TicketType["TicketTypeTicket"] = "TICKET_TYPE_TICKET";
    TicketType["TicketTypeUnspecified"] = "TICKET_TYPE_UNSPECIFIED";
})(TicketType || (TicketType = {}));
/** The user activity type */
export var UserActivityType;
(function (UserActivityType) {
    UserActivityType["UserActivityTypeCreated"] = "USER_ACTIVITY_TYPE_CREATED";
    UserActivityType["UserActivityTypeDeleted"] = "USER_ACTIVITY_TYPE_DELETED";
    UserActivityType["UserActivityTypeDisabled"] = "USER_ACTIVITY_TYPE_DISABLED";
    UserActivityType["UserActivityTypeEdited"] = "USER_ACTIVITY_TYPE_EDITED";
    UserActivityType["UserActivityTypeEnabled"] = "USER_ACTIVITY_TYPE_ENABLED";
    UserActivityType["UserActivityTypeMfaDeleted"] = "USER_ACTIVITY_TYPE_MFA_DELETED";
    UserActivityType["UserActivityTypeUnspecified"] = "USER_ACTIVITY_TYPE_UNSPECIFIED";
})(UserActivityType || (UserActivityType = {}));
export var ConvertImageDocument = "\n    mutation ConvertImage($file: Upload!) {\n  convertImage(file: $file) {\n    uri\n    mime_type\n    filename\n  }\n}\n    ";
export var useConvertImageMutation = function (options) {
    return useMutation(['ConvertImage'], function (variables) { return fetcherWithGraphQLClient(ConvertImageDocument, variables)(); }, options);
};
useConvertImageMutation.getKey = function () { return ['ConvertImage']; };
useConvertImageMutation.fetcher = function (variables, options) { return fetcherWithGraphQLClient(ConvertImageDocument, variables, options); };
export var CreateAgencyCommissionModuleDocument = "\n    mutation CreateAgencyCommissionModule($values: AgencyCommissionModuleInput!) {\n  createAgencyCommissionModule(values: $values)\n}\n    ";
export var useCreateAgencyCommissionModuleMutation = function (options) {
    return useMutation(['CreateAgencyCommissionModule'], function (variables) { return fetcherWithGraphQLClient(CreateAgencyCommissionModuleDocument, variables)(); }, options);
};
useCreateAgencyCommissionModuleMutation.getKey = function () { return ['CreateAgencyCommissionModule']; };
useCreateAgencyCommissionModuleMutation.fetcher = function (variables, options) { return fetcherWithGraphQLClient(CreateAgencyCommissionModuleDocument, variables, options); };
export var CreateAgencyConsolidFeeModuleDocument = "\n    mutation CreateAgencyConsolidFeeModule($values: AgencyConsolidFeeModuleInput!) {\n  createAgencyConsolidFeeModule(values: $values)\n}\n    ";
export var useCreateAgencyConsolidFeeModuleMutation = function (options) {
    return useMutation(['CreateAgencyConsolidFeeModule'], function (variables) { return fetcherWithGraphQLClient(CreateAgencyConsolidFeeModuleDocument, variables)(); }, options);
};
useCreateAgencyConsolidFeeModuleMutation.getKey = function () { return ['CreateAgencyConsolidFeeModule']; };
useCreateAgencyConsolidFeeModuleMutation.fetcher = function (variables, options) { return fetcherWithGraphQLClient(CreateAgencyConsolidFeeModuleDocument, variables, options); };
export var CreateAgencyDocument = "\n    mutation CreateAgency($values: AgencyInput!) {\n  createAgency(values: $values) {\n    id\n  }\n}\n    ";
export var useCreateAgencyMutation = function (options) {
    return useMutation(['CreateAgency'], function (variables) { return fetcherWithGraphQLClient(CreateAgencyDocument, variables)(); }, options);
};
useCreateAgencyMutation.getKey = function () { return ['CreateAgency']; };
useCreateAgencyMutation.fetcher = function (variables, options) { return fetcherWithGraphQLClient(CreateAgencyDocument, variables, options); };
export var CreateAirlineMarkupRuleDocument = "\n    mutation CreateAirlineMarkupRule($airlineMarkupRule: AirlineMarkupRuleInput!) {\n  createAirlineMarkupRule(airline_markup_rule: $airlineMarkupRule) {\n    id\n  }\n}\n    ";
export var useCreateAirlineMarkupRuleMutation = function (options) {
    return useMutation(['CreateAirlineMarkupRule'], function (variables) { return fetcherWithGraphQLClient(CreateAirlineMarkupRuleDocument, variables)(); }, options);
};
useCreateAirlineMarkupRuleMutation.getKey = function () { return ['CreateAirlineMarkupRule']; };
useCreateAirlineMarkupRuleMutation.fetcher = function (variables, options) { return fetcherWithGraphQLClient(CreateAirlineMarkupRuleDocument, variables, options); };
export var CreateAirlineRuleDocument = "\n    mutation CreateAirlineRule($airlineRule: AirlineRuleInput!, $airlineRulesSettingId: Float!) {\n  createAirlineRule(\n    airline_rule: $airlineRule\n    airline_rules_setting_id: $airlineRulesSettingId\n  ) {\n    id\n  }\n}\n    ";
export var useCreateAirlineRuleMutation = function (options) {
    return useMutation(['CreateAirlineRule'], function (variables) { return fetcherWithGraphQLClient(CreateAirlineRuleDocument, variables)(); }, options);
};
useCreateAirlineRuleMutation.getKey = function () { return ['CreateAirlineRule']; };
useCreateAirlineRuleMutation.fetcher = function (variables, options) { return fetcherWithGraphQLClient(CreateAirlineRuleDocument, variables, options); };
export var CreateAirlineRulesSettingDocument = "\n    mutation CreateAirlineRulesSetting($airlineRulesSetting: AirlineRulesSettingInput!) {\n  createAirlineRulesSetting(airline_rules_setting: $airlineRulesSetting) {\n    id\n  }\n}\n    ";
export var useCreateAirlineRulesSettingMutation = function (options) {
    return useMutation(['CreateAirlineRulesSetting'], function (variables) { return fetcherWithGraphQLClient(CreateAirlineRulesSettingDocument, variables)(); }, options);
};
useCreateAirlineRulesSettingMutation.getKey = function () { return ['CreateAirlineRulesSetting']; };
useCreateAirlineRulesSettingMutation.fetcher = function (variables, options) { return fetcherWithGraphQLClient(CreateAirlineRulesSettingDocument, variables, options); };
export var CreateBranchDocument = "\n    mutation CreateBranch($branch: BranchInput!) {\n  createBranch(branch: $branch) {\n    id\n  }\n}\n    ";
export var useCreateBranchMutation = function (options) {
    return useMutation(['CreateBranch'], function (variables) { return fetcherWithGraphQLClient(CreateBranchDocument, variables)(); }, options);
};
useCreateBranchMutation.getKey = function () { return ['CreateBranch']; };
useCreateBranchMutation.fetcher = function (variables, options) { return fetcherWithGraphQLClient(CreateBranchDocument, variables, options); };
export var CreateMarkupModuleDocument = "\n    mutation CreateMarkupModule($markupModule: MarkupModuleInput!) {\n  createMarkupModule(markup_module: $markupModule) {\n    id\n  }\n}\n    ";
export var useCreateMarkupModuleMutation = function (options) {
    return useMutation(['CreateMarkupModule'], function (variables) { return fetcherWithGraphQLClient(CreateMarkupModuleDocument, variables)(); }, options);
};
useCreateMarkupModuleMutation.getKey = function () { return ['CreateMarkupModule']; };
useCreateMarkupModuleMutation.fetcher = function (variables, options) { return fetcherWithGraphQLClient(CreateMarkupModuleDocument, variables, options); };
export var CreatePassengerProfileDocument = "\n    mutation CreatePassengerProfile($data: PassengerProfileInput!) {\n  createPassengerProfile(data: $data) {\n    id\n  }\n}\n    ";
export var useCreatePassengerProfileMutation = function (options) {
    return useMutation(['CreatePassengerProfile'], function (variables) { return fetcherWithGraphQLClient(CreatePassengerProfileDocument, variables)(); }, options);
};
useCreatePassengerProfileMutation.getKey = function () { return ['CreatePassengerProfile']; };
useCreatePassengerProfileMutation.fetcher = function (variables, options) { return fetcherWithGraphQLClient(CreatePassengerProfileDocument, variables, options); };
export var CreateSearchModuleRuleDocument = "\n    mutation CreateSearchModuleRule($rule: SearchModuleRuleInput!, $searchModuleId: Float!) {\n  createSearchModuleRule(rule: $rule, search_module_id: $searchModuleId) {\n    id\n  }\n}\n    ";
export var useCreateSearchModuleRuleMutation = function (options) {
    return useMutation(['CreateSearchModuleRule'], function (variables) { return fetcherWithGraphQLClient(CreateSearchModuleRuleDocument, variables)(); }, options);
};
useCreateSearchModuleRuleMutation.getKey = function () { return ['CreateSearchModuleRule']; };
useCreateSearchModuleRuleMutation.fetcher = function (variables, options) { return fetcherWithGraphQLClient(CreateSearchModuleRuleDocument, variables, options); };
export var CreateSearchModuleDocument = "\n    mutation CreateSearchModule($searchModule: SearchModuleInput!) {\n  createSearchModule(search_module: $searchModule) {\n    id\n  }\n}\n    ";
export var useCreateSearchModuleMutation = function (options) {
    return useMutation(['CreateSearchModule'], function (variables) { return fetcherWithGraphQLClient(CreateSearchModuleDocument, variables)(); }, options);
};
useCreateSearchModuleMutation.getKey = function () { return ['CreateSearchModule']; };
useCreateSearchModuleMutation.fetcher = function (variables, options) { return fetcherWithGraphQLClient(CreateSearchModuleDocument, variables, options); };
export var CreateUserDocument = "\n    mutation CreateUser($user: UserInput!) {\n  createUser(user: $user)\n}\n    ";
export var useCreateUserMutation = function (options) {
    return useMutation(['CreateUser'], function (variables) { return fetcherWithGraphQLClient(CreateUserDocument, variables)(); }, options);
};
useCreateUserMutation.getKey = function () { return ['CreateUser']; };
useCreateUserMutation.fetcher = function (variables, options) { return fetcherWithGraphQLClient(CreateUserDocument, variables, options); };
export var DeleteAgencyCommissionModuleDocument = "\n    mutation DeleteAgencyCommissionModule($deleteAgencyCommissionModuleId: Float!) {\n  deleteAgencyCommissionModule(id: $deleteAgencyCommissionModuleId)\n}\n    ";
export var useDeleteAgencyCommissionModuleMutation = function (options) {
    return useMutation(['DeleteAgencyCommissionModule'], function (variables) { return fetcherWithGraphQLClient(DeleteAgencyCommissionModuleDocument, variables)(); }, options);
};
useDeleteAgencyCommissionModuleMutation.getKey = function () { return ['DeleteAgencyCommissionModule']; };
useDeleteAgencyCommissionModuleMutation.fetcher = function (variables, options) { return fetcherWithGraphQLClient(DeleteAgencyCommissionModuleDocument, variables, options); };
export var DeleteAgencyConsolidFeeModuleDocument = "\n    mutation DeleteAgencyConsolidFeeModule($deleteAgencyConsolidFeeModuleId: Float!) {\n  deleteAgencyConsolidFeeModule(id: $deleteAgencyConsolidFeeModuleId)\n}\n    ";
export var useDeleteAgencyConsolidFeeModuleMutation = function (options) {
    return useMutation(['DeleteAgencyConsolidFeeModule'], function (variables) { return fetcherWithGraphQLClient(DeleteAgencyConsolidFeeModuleDocument, variables)(); }, options);
};
useDeleteAgencyConsolidFeeModuleMutation.getKey = function () { return ['DeleteAgencyConsolidFeeModule']; };
useDeleteAgencyConsolidFeeModuleMutation.fetcher = function (variables, options) { return fetcherWithGraphQLClient(DeleteAgencyConsolidFeeModuleDocument, variables, options); };
export var DeleteAgencyLogoDocument = "\n    mutation DeleteAgencyLogo($agencyId: Float!) {\n  deleteAgencyLogo(agency_id: $agencyId)\n}\n    ";
export var useDeleteAgencyLogoMutation = function (options) {
    return useMutation(['DeleteAgencyLogo'], function (variables) { return fetcherWithGraphQLClient(DeleteAgencyLogoDocument, variables)(); }, options);
};
useDeleteAgencyLogoMutation.getKey = function () { return ['DeleteAgencyLogo']; };
useDeleteAgencyLogoMutation.fetcher = function (variables, options) { return fetcherWithGraphQLClient(DeleteAgencyLogoDocument, variables, options); };
export var DeleteAgencyDocument = "\n    mutation DeleteAgency($deleteAgencyId: Float!) {\n  deleteAgency(id: $deleteAgencyId)\n}\n    ";
export var useDeleteAgencyMutation = function (options) {
    return useMutation(['DeleteAgency'], function (variables) { return fetcherWithGraphQLClient(DeleteAgencyDocument, variables)(); }, options);
};
useDeleteAgencyMutation.getKey = function () { return ['DeleteAgency']; };
useDeleteAgencyMutation.fetcher = function (variables, options) { return fetcherWithGraphQLClient(DeleteAgencyDocument, variables, options); };
export var DeleteAirlineMarkupRuleDocument = "\n    mutation DeleteAirlineMarkupRule($deleteAirlineMarkupRuleId: Float!) {\n  deleteAirlineMarkupRule(id: $deleteAirlineMarkupRuleId)\n}\n    ";
export var useDeleteAirlineMarkupRuleMutation = function (options) {
    return useMutation(['DeleteAirlineMarkupRule'], function (variables) { return fetcherWithGraphQLClient(DeleteAirlineMarkupRuleDocument, variables)(); }, options);
};
useDeleteAirlineMarkupRuleMutation.getKey = function () { return ['DeleteAirlineMarkupRule']; };
useDeleteAirlineMarkupRuleMutation.fetcher = function (variables, options) { return fetcherWithGraphQLClient(DeleteAirlineMarkupRuleDocument, variables, options); };
export var DeleteAirlineRuleDocument = "\n    mutation DeleteAirlineRule($deleteAirlineRuleId: Float!) {\n  deleteAirlineRule(id: $deleteAirlineRuleId)\n}\n    ";
export var useDeleteAirlineRuleMutation = function (options) {
    return useMutation(['DeleteAirlineRule'], function (variables) { return fetcherWithGraphQLClient(DeleteAirlineRuleDocument, variables)(); }, options);
};
useDeleteAirlineRuleMutation.getKey = function () { return ['DeleteAirlineRule']; };
useDeleteAirlineRuleMutation.fetcher = function (variables, options) { return fetcherWithGraphQLClient(DeleteAirlineRuleDocument, variables, options); };
export var DeleteAirlineRulesSettingDocument = "\n    mutation DeleteAirlineRulesSetting($deleteAirlineRulesSettingId: Float!) {\n  deleteAirlineRulesSetting(id: $deleteAirlineRulesSettingId)\n}\n    ";
export var useDeleteAirlineRulesSettingMutation = function (options) {
    return useMutation(['DeleteAirlineRulesSetting'], function (variables) { return fetcherWithGraphQLClient(DeleteAirlineRulesSettingDocument, variables)(); }, options);
};
useDeleteAirlineRulesSettingMutation.getKey = function () { return ['DeleteAirlineRulesSetting']; };
useDeleteAirlineRulesSettingMutation.fetcher = function (variables, options) { return fetcherWithGraphQLClient(DeleteAirlineRulesSettingDocument, variables, options); };
export var DeleteBranchDocument = "\n    mutation DeleteBranch($deleteBranchId: Float!) {\n  deleteBranch(id: $deleteBranchId)\n}\n    ";
export var useDeleteBranchMutation = function (options) {
    return useMutation(['DeleteBranch'], function (variables) { return fetcherWithGraphQLClient(DeleteBranchDocument, variables)(); }, options);
};
useDeleteBranchMutation.getKey = function () { return ['DeleteBranch']; };
useDeleteBranchMutation.fetcher = function (variables, options) { return fetcherWithGraphQLClient(DeleteBranchDocument, variables, options); };
export var DeleteMarkupModuleDocument = "\n    mutation DeleteMarkupModule($deleteMarkupModuleId: Float!) {\n  deleteMarkupModule(id: $deleteMarkupModuleId)\n}\n    ";
export var useDeleteMarkupModuleMutation = function (options) {
    return useMutation(['DeleteMarkupModule'], function (variables) { return fetcherWithGraphQLClient(DeleteMarkupModuleDocument, variables)(); }, options);
};
useDeleteMarkupModuleMutation.getKey = function () { return ['DeleteMarkupModule']; };
useDeleteMarkupModuleMutation.fetcher = function (variables, options) { return fetcherWithGraphQLClient(DeleteMarkupModuleDocument, variables, options); };
export var DeletePassengerProfileDocument = "\n    mutation DeletePassengerProfile($data: DeletePassengerProfileInput!) {\n  deletePassengerProfile(data: $data)\n}\n    ";
export var useDeletePassengerProfileMutation = function (options) {
    return useMutation(['DeletePassengerProfile'], function (variables) { return fetcherWithGraphQLClient(DeletePassengerProfileDocument, variables)(); }, options);
};
useDeletePassengerProfileMutation.getKey = function () { return ['DeletePassengerProfile']; };
useDeletePassengerProfileMutation.fetcher = function (variables, options) { return fetcherWithGraphQLClient(DeletePassengerProfileDocument, variables, options); };
export var DeleteSearchModuleRuleDocument = "\n    mutation DeleteSearchModuleRule($deleteSearchModuleRuleId: Float!) {\n  deleteSearchModuleRule(id: $deleteSearchModuleRuleId)\n}\n    ";
export var useDeleteSearchModuleRuleMutation = function (options) {
    return useMutation(['DeleteSearchModuleRule'], function (variables) { return fetcherWithGraphQLClient(DeleteSearchModuleRuleDocument, variables)(); }, options);
};
useDeleteSearchModuleRuleMutation.getKey = function () { return ['DeleteSearchModuleRule']; };
useDeleteSearchModuleRuleMutation.fetcher = function (variables, options) { return fetcherWithGraphQLClient(DeleteSearchModuleRuleDocument, variables, options); };
export var DeleteSearchModuleDocument = "\n    mutation DeleteSearchModule($deleteSearchModuleId: Float!) {\n  deleteSearchModule(id: $deleteSearchModuleId)\n}\n    ";
export var useDeleteSearchModuleMutation = function (options) {
    return useMutation(['DeleteSearchModule'], function (variables) { return fetcherWithGraphQLClient(DeleteSearchModuleDocument, variables)(); }, options);
};
useDeleteSearchModuleMutation.getKey = function () { return ['DeleteSearchModule']; };
useDeleteSearchModuleMutation.fetcher = function (variables, options) { return fetcherWithGraphQLClient(DeleteSearchModuleDocument, variables, options); };
export var DeleteUserMfaDocument = "\n    mutation DeleteUserMFA($deleteUserMfaId: Float!) {\n  deleteUserMFA(id: $deleteUserMfaId)\n}\n    ";
export var useDeleteUserMfaMutation = function (options) {
    return useMutation(['DeleteUserMFA'], function (variables) { return fetcherWithGraphQLClient(DeleteUserMfaDocument, variables)(); }, options);
};
useDeleteUserMfaMutation.getKey = function () { return ['DeleteUserMFA']; };
useDeleteUserMfaMutation.fetcher = function (variables, options) { return fetcherWithGraphQLClient(DeleteUserMfaDocument, variables, options); };
export var DeleteUserDocument = "\n    mutation DeleteUser($deleteUserId: Float!) {\n  deleteUser(id: $deleteUserId)\n}\n    ";
export var useDeleteUserMutation = function (options) {
    return useMutation(['DeleteUser'], function (variables) { return fetcherWithGraphQLClient(DeleteUserDocument, variables)(); }, options);
};
useDeleteUserMutation.getKey = function () { return ['DeleteUser']; };
useDeleteUserMutation.fetcher = function (variables, options) { return fetcherWithGraphQLClient(DeleteUserDocument, variables, options); };
export var DisableAgencyDocument = "\n    mutation DisableAgency($disableAgencyId: Float!) {\n  disableAgency(id: $disableAgencyId)\n}\n    ";
export var useDisableAgencyMutation = function (options) {
    return useMutation(['DisableAgency'], function (variables) { return fetcherWithGraphQLClient(DisableAgencyDocument, variables)(); }, options);
};
useDisableAgencyMutation.getKey = function () { return ['DisableAgency']; };
useDisableAgencyMutation.fetcher = function (variables, options) { return fetcherWithGraphQLClient(DisableAgencyDocument, variables, options); };
export var DisablePassengerProfileDocument = "\n    mutation DisablePassengerProfile($data: DisablePassengerProfileInput!) {\n  disablePassengerProfile(data: $data)\n}\n    ";
export var useDisablePassengerProfileMutation = function (options) {
    return useMutation(['DisablePassengerProfile'], function (variables) { return fetcherWithGraphQLClient(DisablePassengerProfileDocument, variables)(); }, options);
};
useDisablePassengerProfileMutation.getKey = function () { return ['DisablePassengerProfile']; };
useDisablePassengerProfileMutation.fetcher = function (variables, options) { return fetcherWithGraphQLClient(DisablePassengerProfileDocument, variables, options); };
export var DisableUserDocument = "\n    mutation DisableUser($disableUserId: Float!) {\n  disableUser(id: $disableUserId)\n}\n    ";
export var useDisableUserMutation = function (options) {
    return useMutation(['DisableUser'], function (variables) { return fetcherWithGraphQLClient(DisableUserDocument, variables)(); }, options);
};
useDisableUserMutation.getKey = function () { return ['DisableUser']; };
useDisableUserMutation.fetcher = function (variables, options) { return fetcherWithGraphQLClient(DisableUserDocument, variables, options); };
export var EnableAgencyDocument = "\n    mutation EnableAgency($enableAgencyId: Float!) {\n  enableAgency(id: $enableAgencyId)\n}\n    ";
export var useEnableAgencyMutation = function (options) {
    return useMutation(['EnableAgency'], function (variables) { return fetcherWithGraphQLClient(EnableAgencyDocument, variables)(); }, options);
};
useEnableAgencyMutation.getKey = function () { return ['EnableAgency']; };
useEnableAgencyMutation.fetcher = function (variables, options) { return fetcherWithGraphQLClient(EnableAgencyDocument, variables, options); };
export var EnablePassengerProfileDocument = "\n    mutation EnablePassengerProfile($data: EnablePassengerProfileInput!) {\n  enablePassengerProfile(data: $data)\n}\n    ";
export var useEnablePassengerProfileMutation = function (options) {
    return useMutation(['EnablePassengerProfile'], function (variables) { return fetcherWithGraphQLClient(EnablePassengerProfileDocument, variables)(); }, options);
};
useEnablePassengerProfileMutation.getKey = function () { return ['EnablePassengerProfile']; };
useEnablePassengerProfileMutation.fetcher = function (variables, options) { return fetcherWithGraphQLClient(EnablePassengerProfileDocument, variables, options); };
export var EnableUserDocument = "\n    mutation EnableUser($enableUserId: Float!) {\n  enableUser(id: $enableUserId)\n}\n    ";
export var useEnableUserMutation = function (options) {
    return useMutation(['EnableUser'], function (variables) { return fetcherWithGraphQLClient(EnableUserDocument, variables)(); }, options);
};
useEnableUserMutation.getKey = function () { return ['EnableUser']; };
useEnableUserMutation.fetcher = function (variables, options) { return fetcherWithGraphQLClient(EnableUserDocument, variables, options); };
export var LoggedInDocument = "\n    mutation LoggedIn {\n  loggedIn {\n    id\n    username\n    email\n    first_name\n    last_name\n    phone_number\n    permissions\n    roles\n    avatar {\n      small_uri\n      medium_uri\n      large_uri\n    }\n    agency_id\n    agency {\n      crm_company_id\n      name\n      logo {\n        small_uri\n      }\n    }\n  }\n}\n    ";
export var useLoggedInMutation = function (options) {
    return useMutation(['LoggedIn'], function (variables) { return fetcherWithGraphQLClient(LoggedInDocument, variables)(); }, options);
};
useLoggedInMutation.getKey = function () { return ['LoggedIn']; };
useLoggedInMutation.fetcher = function (variables, options) { return fetcherWithGraphQLClient(LoggedInDocument, variables, options); };
export var SaveAirlineMarkupRuleDocument = "\n    mutation SaveAirlineMarkupRule($airlineMarkupRule: AirlineMarkupRuleValues!) {\n  saveAirlineMarkupRule(airline_markup_rule: $airlineMarkupRule)\n}\n    ";
export var useSaveAirlineMarkupRuleMutation = function (options) {
    return useMutation(['SaveAirlineMarkupRule'], function (variables) { return fetcherWithGraphQLClient(SaveAirlineMarkupRuleDocument, variables)(); }, options);
};
useSaveAirlineMarkupRuleMutation.getKey = function () { return ['SaveAirlineMarkupRule']; };
useSaveAirlineMarkupRuleMutation.fetcher = function (variables, options) { return fetcherWithGraphQLClient(SaveAirlineMarkupRuleDocument, variables, options); };
export var SaveAirlineRulesSettingDocument = "\n    mutation SaveAirlineRulesSetting($airlineRulesSetting: AirlineRulesSettingInput!) {\n  saveAirlineRulesSetting(airline_rules_setting: $airlineRulesSetting)\n}\n    ";
export var useSaveAirlineRulesSettingMutation = function (options) {
    return useMutation(['SaveAirlineRulesSetting'], function (variables) { return fetcherWithGraphQLClient(SaveAirlineRulesSettingDocument, variables)(); }, options);
};
useSaveAirlineRulesSettingMutation.getKey = function () { return ['SaveAirlineRulesSetting']; };
useSaveAirlineRulesSettingMutation.fetcher = function (variables, options) { return fetcherWithGraphQLClient(SaveAirlineRulesSettingDocument, variables, options); };
export var SaveAirlineRulesDocument = "\n    mutation SaveAirlineRules($airlineRule: AirlineRuleInput!) {\n  saveAirlineRules(airline_rule: $airlineRule) {\n    id\n  }\n}\n    ";
export var useSaveAirlineRulesMutation = function (options) {
    return useMutation(['SaveAirlineRules'], function (variables) { return fetcherWithGraphQLClient(SaveAirlineRulesDocument, variables)(); }, options);
};
useSaveAirlineRulesMutation.getKey = function () { return ['SaveAirlineRules']; };
useSaveAirlineRulesMutation.fetcher = function (variables, options) { return fetcherWithGraphQLClient(SaveAirlineRulesDocument, variables, options); };
export var SortAirlineRulesDocument = "\n    mutation SortAirlineRules($airlineRules: [AirlineRuleInput!]!) {\n  sortAirlineRules(airline_rules: $airlineRules)\n}\n    ";
export var useSortAirlineRulesMutation = function (options) {
    return useMutation(['SortAirlineRules'], function (variables) { return fetcherWithGraphQLClient(SortAirlineRulesDocument, variables)(); }, options);
};
useSortAirlineRulesMutation.getKey = function () { return ['SortAirlineRules']; };
useSortAirlineRulesMutation.fetcher = function (variables, options) { return fetcherWithGraphQLClient(SortAirlineRulesDocument, variables, options); };
export var SortSearchModuleRulesDocument = "\n    mutation SortSearchModuleRules($updatePositions: [SearchModuleRulePositionInput!]!) {\n  sortSearchModuleRules(update_positions: $updatePositions)\n}\n    ";
export var useSortSearchModuleRulesMutation = function (options) {
    return useMutation(['SortSearchModuleRules'], function (variables) { return fetcherWithGraphQLClient(SortSearchModuleRulesDocument, variables)(); }, options);
};
useSortSearchModuleRulesMutation.getKey = function () { return ['SortSearchModuleRules']; };
useSortSearchModuleRulesMutation.fetcher = function (variables, options) { return fetcherWithGraphQLClient(SortSearchModuleRulesDocument, variables, options); };
export var PutAgencyCommissionModuleDocument = "\n    mutation PutAgencyCommissionModule($values: AgencyCommissionModuleValues!) {\n  putAgencyCommissionModule(values: $values)\n}\n    ";
export var usePutAgencyCommissionModuleMutation = function (options) {
    return useMutation(['PutAgencyCommissionModule'], function (variables) { return fetcherWithGraphQLClient(PutAgencyCommissionModuleDocument, variables)(); }, options);
};
usePutAgencyCommissionModuleMutation.getKey = function () { return ['PutAgencyCommissionModule']; };
usePutAgencyCommissionModuleMutation.fetcher = function (variables, options) { return fetcherWithGraphQLClient(PutAgencyCommissionModuleDocument, variables, options); };
export var PutAgencyConsolidFeeModuleDocument = "\n    mutation PutAgencyConsolidFeeModule($values: AgencyConsolidFeeModuleValues!) {\n  putAgencyConsolidFeeModule(values: $values)\n}\n    ";
export var usePutAgencyConsolidFeeModuleMutation = function (options) {
    return useMutation(['PutAgencyConsolidFeeModule'], function (variables) { return fetcherWithGraphQLClient(PutAgencyConsolidFeeModuleDocument, variables)(); }, options);
};
usePutAgencyConsolidFeeModuleMutation.getKey = function () { return ['PutAgencyConsolidFeeModule']; };
usePutAgencyConsolidFeeModuleMutation.fetcher = function (variables, options) { return fetcherWithGraphQLClient(PutAgencyConsolidFeeModuleDocument, variables, options); };
export var UpdateAgencyCreditDocument = "\n    mutation UpdateAgencyCredit($values: AgencyCreditValues!, $updateAgencyCreditId: Float!) {\n  updateAgencyCredit(values: $values, id: $updateAgencyCreditId)\n}\n    ";
export var useUpdateAgencyCreditMutation = function (options) {
    return useMutation(['UpdateAgencyCredit'], function (variables) { return fetcherWithGraphQLClient(UpdateAgencyCreditDocument, variables)(); }, options);
};
useUpdateAgencyCreditMutation.getKey = function () { return ['UpdateAgencyCredit']; };
useUpdateAgencyCreditMutation.fetcher = function (variables, options) { return fetcherWithGraphQLClient(UpdateAgencyCreditDocument, variables, options); };
export var SavePassengerProfileDocument = "\n    mutation SavePassengerProfile($values: PassengerProfileValues!, $savePassengerProfileId: String!) {\n  savePassengerProfile(values: $values, id: $savePassengerProfileId) {\n    success\n  }\n}\n    ";
export var useSavePassengerProfileMutation = function (options) {
    return useMutation(['SavePassengerProfile'], function (variables) { return fetcherWithGraphQLClient(SavePassengerProfileDocument, variables)(); }, options);
};
useSavePassengerProfileMutation.getKey = function () { return ['SavePassengerProfile']; };
useSavePassengerProfileMutation.fetcher = function (variables, options) { return fetcherWithGraphQLClient(SavePassengerProfileDocument, variables, options); };
export var UpdateAgencyPaymentCardCreditDocument = "\n    mutation UpdateAgencyPaymentCardCredit($values: PaymentCardAgencyCreditValues!, $updateAgencyPaymentCardCreditId: Float!) {\n  updateAgencyPaymentCardCredit(\n    values: $values\n    id: $updateAgencyPaymentCardCreditId\n  )\n}\n    ";
export var useUpdateAgencyPaymentCardCreditMutation = function (options) {
    return useMutation(['UpdateAgencyPaymentCardCredit'], function (variables) { return fetcherWithGraphQLClient(UpdateAgencyPaymentCardCreditDocument, variables)(); }, options);
};
useUpdateAgencyPaymentCardCreditMutation.getKey = function () { return ['UpdateAgencyPaymentCardCredit']; };
useUpdateAgencyPaymentCardCreditMutation.fetcher = function (variables, options) { return fetcherWithGraphQLClient(UpdateAgencyPaymentCardCreditDocument, variables, options); };
export var UpdateAgencyDocument = "\n    mutation UpdateAgency($values: AgencyValues!, $updateAgencyId: Float!) {\n  updateAgency(values: $values, id: $updateAgencyId) {\n    id\n  }\n}\n    ";
export var useUpdateAgencyMutation = function (options) {
    return useMutation(['UpdateAgency'], function (variables) { return fetcherWithGraphQLClient(UpdateAgencyDocument, variables)(); }, options);
};
useUpdateAgencyMutation.getKey = function () { return ['UpdateAgency']; };
useUpdateAgencyMutation.fetcher = function (variables, options) { return fetcherWithGraphQLClient(UpdateAgencyDocument, variables, options); };
export var UpdateBranchDefaultModuleDocument = "\n    mutation UpdateBranchDefaultModule($input: BranchUpdateModulesInput!) {\n  updateBranchDefaultModules(input: $input)\n}\n    ";
export var useUpdateBranchDefaultModuleMutation = function (options) {
    return useMutation(['UpdateBranchDefaultModule'], function (variables) { return fetcherWithGraphQLClient(UpdateBranchDefaultModuleDocument, variables)(); }, options);
};
useUpdateBranchDefaultModuleMutation.getKey = function () { return ['UpdateBranchDefaultModule']; };
useUpdateBranchDefaultModuleMutation.fetcher = function (variables, options) { return fetcherWithGraphQLClient(UpdateBranchDefaultModuleDocument, variables, options); };
export var UpdateBranchInfoDocument = "\n    mutation UpdateBranchInfo($branch: BranchValues!) {\n  saveBranch(branch: $branch)\n}\n    ";
export var useUpdateBranchInfoMutation = function (options) {
    return useMutation(['UpdateBranchInfo'], function (variables) { return fetcherWithGraphQLClient(UpdateBranchInfoDocument, variables)(); }, options);
};
useUpdateBranchInfoMutation.getKey = function () { return ['UpdateBranchInfo']; };
useUpdateBranchInfoMutation.fetcher = function (variables, options) { return fetcherWithGraphQLClient(UpdateBranchInfoDocument, variables, options); };
export var SaveSearchModuleRuleDocument = "\n    mutation SaveSearchModuleRule($rule: SearchModuleRuleInput!) {\n  saveSearchModuleRule(rule: $rule)\n}\n    ";
export var useSaveSearchModuleRuleMutation = function (options) {
    return useMutation(['SaveSearchModuleRule'], function (variables) { return fetcherWithGraphQLClient(SaveSearchModuleRuleDocument, variables)(); }, options);
};
useSaveSearchModuleRuleMutation.getKey = function () { return ['SaveSearchModuleRule']; };
useSaveSearchModuleRuleMutation.fetcher = function (variables, options) { return fetcherWithGraphQLClient(SaveSearchModuleRuleDocument, variables, options); };
export var UpdateSearchModuleDocument = "\n    mutation UpdateSearchModule($searchModule: SearchModuleInput!) {\n  saveSearchModule(search_module: $searchModule)\n}\n    ";
export var useUpdateSearchModuleMutation = function (options) {
    return useMutation(['UpdateSearchModule'], function (variables) { return fetcherWithGraphQLClient(UpdateSearchModuleDocument, variables)(); }, options);
};
useUpdateSearchModuleMutation.getKey = function () { return ['UpdateSearchModule']; };
useUpdateSearchModuleMutation.fetcher = function (variables, options) { return fetcherWithGraphQLClient(UpdateSearchModuleDocument, variables, options); };
export var UpdateUserDocument = "\n    mutation UpdateUser($values: UserValues!, $email: String!) {\n  updateUser(values: $values, email: $email) {\n    id\n  }\n}\n    ";
export var useUpdateUserMutation = function (options) {
    return useMutation(['UpdateUser'], function (variables) { return fetcherWithGraphQLClient(UpdateUserDocument, variables)(); }, options);
};
useUpdateUserMutation.getKey = function () { return ['UpdateUser']; };
useUpdateUserMutation.fetcher = function (variables, options) { return fetcherWithGraphQLClient(UpdateUserDocument, variables, options); };
export var UploadAgencyLogoDocument = "\n    mutation UploadAgencyLogo($agencyId: Float!, $file: Upload!) {\n  uploadAgencyLogo(agency_id: $agencyId, file: $file)\n}\n    ";
export var useUploadAgencyLogoMutation = function (options) {
    return useMutation(['UploadAgencyLogo'], function (variables) { return fetcherWithGraphQLClient(UploadAgencyLogoDocument, variables)(); }, options);
};
useUploadAgencyLogoMutation.getKey = function () { return ['UploadAgencyLogo']; };
useUploadAgencyLogoMutation.fetcher = function (variables, options) { return fetcherWithGraphQLClient(UploadAgencyLogoDocument, variables, options); };
export var GetAgencyActivitiesDocument = "\n    query GetAgencyActivities($getAgencyActivitiesId: Float!) {\n  getAgencyActivities(id: $getAgencyActivitiesId) {\n    activity_type\n    id\n    created_at\n    agency_id\n    user_agency_id\n    user_id\n    err\n    transaction\n    user_info {\n      first_name\n      last_name\n    }\n  }\n}\n    ";
export var useGetAgencyActivitiesQuery = function (variables, options) {
    return useQuery(['GetAgencyActivities', variables], fetcherWithGraphQLClient(GetAgencyActivitiesDocument, variables), options);
};
useGetAgencyActivitiesQuery.getKey = function (variables) { return ['GetAgencyActivities', variables]; };
;
useGetAgencyActivitiesQuery.fetcher = function (variables, options) { return fetcherWithGraphQLClient(GetAgencyActivitiesDocument, variables, options); };
export var GetAgencyBalanceDetailsDocument = "\n    query GetAgencyBalanceDetails($data: GetAgencyBalanceDetailsInput!) {\n  getAgencyBalanceDetails(data: $data) {\n    tickets {\n      number\n      booking_id\n      created_at\n      user_info {\n        last_name\n        first_name\n      }\n      provider {\n        locator\n      }\n      price {\n        total\n      }\n      validating_carrier_info {\n        code\n        long_name\n      }\n    }\n    credit_amount\n    available_amount\n    used_amount\n  }\n}\n    ";
export var useGetAgencyBalanceDetailsQuery = function (variables, options) {
    return useQuery(['GetAgencyBalanceDetails', variables], fetcherWithGraphQLClient(GetAgencyBalanceDetailsDocument, variables), options);
};
useGetAgencyBalanceDetailsQuery.getKey = function (variables) { return ['GetAgencyBalanceDetails', variables]; };
;
useGetAgencyBalanceDetailsQuery.fetcher = function (variables, options) { return fetcherWithGraphQLClient(GetAgencyBalanceDetailsDocument, variables, options); };
export var GetAgencyBalanceDocument = "\n    query GetAgencyBalance($data: GetAgencyBalanceInput!) {\n  getAgencyBalance(data: $data) {\n    credit_amount\n    used_amount\n    available_amount\n  }\n}\n    ";
export var useGetAgencyBalanceQuery = function (variables, options) {
    return useQuery(['GetAgencyBalance', variables], fetcherWithGraphQLClient(GetAgencyBalanceDocument, variables), options);
};
useGetAgencyBalanceQuery.getKey = function (variables) { return ['GetAgencyBalance', variables]; };
;
useGetAgencyBalanceQuery.fetcher = function (variables, options) { return fetcherWithGraphQLClient(GetAgencyBalanceDocument, variables, options); };
export var GetCommissionModuleAgenciesDocument = "\n    query GetCommissionModuleAgencies($getAgencyCommissionModuleId: Float) {\n  getAgencyCommissionModule(id: $getAgencyCommissionModuleId) {\n    agencies {\n      id\n      name\n      logo {\n        small_uri\n      }\n    }\n  }\n}\n    ";
export var useGetCommissionModuleAgenciesQuery = function (variables, options) {
    return useQuery(variables === undefined ? ['GetCommissionModuleAgencies'] : ['GetCommissionModuleAgencies', variables], fetcherWithGraphQLClient(GetCommissionModuleAgenciesDocument, variables), options);
};
useGetCommissionModuleAgenciesQuery.getKey = function (variables) { return variables === undefined ? ['GetCommissionModuleAgencies'] : ['GetCommissionModuleAgencies', variables]; };
;
useGetCommissionModuleAgenciesQuery.fetcher = function (variables, options) { return fetcherWithGraphQLClient(GetCommissionModuleAgenciesDocument, variables, options); };
export var GetAgencyCommissionModuleDocument = "\n    query GetAgencyCommissionModule($getAgencyCommissionModuleId: Float) {\n  getAgencyCommissionModule(id: $getAgencyCommissionModuleId) {\n    id\n    name\n    created_at\n    description\n    airlines_commissions {\n      id\n      airline_code\n      from_commission\n      to_commission\n      commission\n      commission_type\n    }\n  }\n}\n    ";
export var useGetAgencyCommissionModuleQuery = function (variables, options) {
    return useQuery(variables === undefined ? ['GetAgencyCommissionModule'] : ['GetAgencyCommissionModule', variables], fetcherWithGraphQLClient(GetAgencyCommissionModuleDocument, variables), options);
};
useGetAgencyCommissionModuleQuery.getKey = function (variables) { return variables === undefined ? ['GetAgencyCommissionModule'] : ['GetAgencyCommissionModule', variables]; };
;
useGetAgencyCommissionModuleQuery.fetcher = function (variables, options) { return fetcherWithGraphQLClient(GetAgencyCommissionModuleDocument, variables, options); };
export var GetAgencyCommissionModulesDocument = "\n    query GetAgencyCommissionModules {\n  getAgencyCommissionModules {\n    id\n    name\n    description\n    created_at\n    last_update\n  }\n}\n    ";
export var useGetAgencyCommissionModulesQuery = function (variables, options) {
    return useQuery(variables === undefined ? ['GetAgencyCommissionModules'] : ['GetAgencyCommissionModules', variables], fetcherWithGraphQLClient(GetAgencyCommissionModulesDocument, variables), options);
};
useGetAgencyCommissionModulesQuery.getKey = function (variables) { return variables === undefined ? ['GetAgencyCommissionModules'] : ['GetAgencyCommissionModules', variables]; };
;
useGetAgencyCommissionModulesQuery.fetcher = function (variables, options) { return fetcherWithGraphQLClient(GetAgencyCommissionModulesDocument, variables, options); };
export var GetConsolidFeeModuleAgenciesDocument = "\n    query GetConsolidFeeModuleAgencies($getAgencyConsolidFeeModuleId: String) {\n  getAgencyConsolidFeeModule(id: $getAgencyConsolidFeeModuleId) {\n    agencies {\n      id\n      name\n      logo {\n        small_uri\n      }\n    }\n  }\n}\n    ";
export var useGetConsolidFeeModuleAgenciesQuery = function (variables, options) {
    return useQuery(variables === undefined ? ['GetConsolidFeeModuleAgencies'] : ['GetConsolidFeeModuleAgencies', variables], fetcherWithGraphQLClient(GetConsolidFeeModuleAgenciesDocument, variables), options);
};
useGetConsolidFeeModuleAgenciesQuery.getKey = function (variables) { return variables === undefined ? ['GetConsolidFeeModuleAgencies'] : ['GetConsolidFeeModuleAgencies', variables]; };
;
useGetConsolidFeeModuleAgenciesQuery.fetcher = function (variables, options) { return fetcherWithGraphQLClient(GetConsolidFeeModuleAgenciesDocument, variables, options); };
export var GetAgencyConsolidFeeModuleDocument = "\n    query GetAgencyConsolidFeeModule($getAgencyConsolidFeeModuleId: String) {\n  getAgencyConsolidFeeModule(id: $getAgencyConsolidFeeModuleId) {\n    name\n    id\n    description\n    economy_consolid_fee\n    created_at\n    business_consolid_fee\n  }\n}\n    ";
export var useGetAgencyConsolidFeeModuleQuery = function (variables, options) {
    return useQuery(variables === undefined ? ['GetAgencyConsolidFeeModule'] : ['GetAgencyConsolidFeeModule', variables], fetcherWithGraphQLClient(GetAgencyConsolidFeeModuleDocument, variables), options);
};
useGetAgencyConsolidFeeModuleQuery.getKey = function (variables) { return variables === undefined ? ['GetAgencyConsolidFeeModule'] : ['GetAgencyConsolidFeeModule', variables]; };
;
useGetAgencyConsolidFeeModuleQuery.fetcher = function (variables, options) { return fetcherWithGraphQLClient(GetAgencyConsolidFeeModuleDocument, variables, options); };
export var GetAgencyCreditDocument = "\n    query GetAgencyCredit($agencyId: Float!) {\n  getAgencyCredit(agency_id: $agencyId) {\n    credit_enabled\n    credit_amount\n    credit_period_type\n  }\n}\n    ";
export var useGetAgencyCreditQuery = function (variables, options) {
    return useQuery(['GetAgencyCredit', variables], fetcherWithGraphQLClient(GetAgencyCreditDocument, variables), options);
};
useGetAgencyCreditQuery.getKey = function (variables) { return ['GetAgencyCredit', variables]; };
;
useGetAgencyCreditQuery.fetcher = function (variables, options) { return fetcherWithGraphQLClient(GetAgencyCreditDocument, variables, options); };
export var GetAgencyDetailsDocument = "\n    query GetAgencyDetails($agencyId: Float!) {\n  agency: getAgency(id: $agencyId) {\n    id\n    name\n    cnpj\n    email\n    phone_number\n    crm_company_id\n    language\n    timezone\n    booking_contact_type\n    default_business_fee\n    default_economy_fee\n    default_domestic_fee_minimum_amount\n    default_domestic_fee_percent\n    default_international_fee_minimum_amount\n    default_international_fee_percent\n    default_email_copy_agent\n    default_email_message\n    default_email_price_breakdown_type\n    maximum_fee_percent\n    markup_module_id\n    branch_id\n    commission_module\n    consolid_fee_module\n    credit_enabled\n    pix_enabled\n    credit_amount\n    credit_period_type\n    created_at\n    enabled\n    payment_card_credit_enabled\n    payment_card_credit_amount\n    payment_card_credit_period_type\n    logo {\n      mime_type\n      small_uri\n      medium_uri\n      large_uri\n      original_uri\n    }\n    created_by_info {\n      first_name\n    }\n    markup_module_info {\n      id\n      name\n    }\n    branch_info {\n      id\n      name\n      description\n    }\n    commission_module_info {\n      id\n      name\n      description\n    }\n    consolid_fee_module_info {\n      id\n      name\n      description\n    }\n    search_module_info {\n      id\n      name\n      description\n    }\n  }\n  balanceCreditCard: getAgencyBalance(\n    data: {agency_id: $agencyId, fop_type: FOP_TYPE_CREDITCARD}\n  ) {\n    credit_amount\n    used_amount\n    available_amount\n  }\n  balanceInvoice: getAgencyBalance(\n    data: {agency_id: $agencyId, fop_type: FOP_TYPE_INVOICE}\n  ) {\n    credit_amount\n    used_amount\n    available_amount\n  }\n  users: getUsers(filter: {agency_id: $agencyId}) {\n    id\n    first_name\n    last_name\n    email\n  }\n}\n    ";
export var useGetAgencyDetailsQuery = function (variables, options) {
    return useQuery(['GetAgencyDetails', variables], fetcherWithGraphQLClient(GetAgencyDetailsDocument, variables), options);
};
useGetAgencyDetailsQuery.getKey = function (variables) { return ['GetAgencyDetails', variables]; };
;
useGetAgencyDetailsQuery.fetcher = function (variables, options) { return fetcherWithGraphQLClient(GetAgencyDetailsDocument, variables, options); };
export var ListAgencyPassengersDocument = "\n    query ListAgencyPassengers($filter: ListPassengerProfileFilter!) {\n  listPassengerProfiles(filter: $filter) {\n    passenger_profiles {\n      id\n      agency_id\n      first_name\n      middle_name\n      last_name\n      email\n      status\n      pax_type\n      created_at\n      created_by_info {\n        id\n        first_name\n        last_name\n      }\n    }\n  }\n}\n    ";
export var useListAgencyPassengersQuery = function (variables, options) {
    return useQuery(['ListAgencyPassengers', variables], fetcherWithGraphQLClient(ListAgencyPassengersDocument, variables), options);
};
useListAgencyPassengersQuery.getKey = function (variables) { return ['ListAgencyPassengers', variables]; };
;
useListAgencyPassengersQuery.fetcher = function (variables, options) { return fetcherWithGraphQLClient(ListAgencyPassengersDocument, variables, options); };
export var GetAgencyDocument = "\n    query GetAgency($getAgencyId: Float!) {\n  getAgency(id: $getAgencyId) {\n    id\n    name\n    cnpj\n    email\n    phone_number\n    crm_company_id\n    language\n    timezone\n    booking_contact_type\n    default_business_fee\n    default_economy_fee\n    default_domestic_fee_minimum_amount\n    default_domestic_fee_percent\n    default_international_fee_minimum_amount\n    default_international_fee_percent\n    default_email_copy_agent\n    default_email_message\n    default_email_price_breakdown_type\n    maximum_fee_percent\n    markup_module_id\n    branch_id\n    commission_module\n    consolid_fee_module\n    credit_enabled\n    pix_enabled\n    credit_amount\n    credit_period_type\n    created_at\n    enabled\n    payment_card_credit_enabled\n    payment_card_credit_amount\n    payment_card_credit_period_type\n    logo {\n      mime_type\n      small_uri\n      medium_uri\n      large_uri\n      original_uri\n    }\n    created_by_info {\n      first_name\n    }\n    markup_module_info {\n      id\n      name\n    }\n    branch_info {\n      id\n      name\n      description\n    }\n    commission_module_info {\n      id\n      name\n      description\n    }\n    consolid_fee_module_info {\n      id\n      name\n      description\n    }\n    search_module_info {\n      id\n      name\n      description\n    }\n  }\n}\n    ";
export var useGetAgencyQuery = function (variables, options) {
    return useQuery(['GetAgency', variables], fetcherWithGraphQLClient(GetAgencyDocument, variables), options);
};
useGetAgencyQuery.getKey = function (variables) { return ['GetAgency', variables]; };
;
useGetAgencyQuery.fetcher = function (variables, options) { return fetcherWithGraphQLClient(GetAgencyDocument, variables, options); };
export var ListAirlineRulesSettingActivitiesDocument = "\n    query ListAirlineRulesSettingActivities($listAirlineRulesSettingActivitiesId: Float!) {\n  listAirlineRulesSettingActivities(id: $listAirlineRulesSettingActivitiesId) {\n    activities {\n      id\n      created_at\n      activity_type\n      user_id\n      user_agency_id\n      err\n      transaction\n      user_info {\n        id\n        first_name\n        last_name\n        email\n      }\n      agency_info {\n        id\n        name\n        logo {\n          mime_type\n          small_uri\n          medium_uri\n          large_uri\n          original_uri\n        }\n      }\n    }\n  }\n}\n    ";
export var useListAirlineRulesSettingActivitiesQuery = function (variables, options) {
    return useQuery(['ListAirlineRulesSettingActivities', variables], fetcherWithGraphQLClient(ListAirlineRulesSettingActivitiesDocument, variables), options);
};
useListAirlineRulesSettingActivitiesQuery.getKey = function (variables) { return ['ListAirlineRulesSettingActivities', variables]; };
;
useListAirlineRulesSettingActivitiesQuery.fetcher = function (variables, options) { return fetcherWithGraphQLClient(ListAirlineRulesSettingActivitiesDocument, variables, options); };
export var GetAirlineRuleActivitiesDocument = "\n    query getAirlineRuleActivities($listAirlineRuleActivitiesId: Float!) {\n  listAirlineRuleActivities(id: $listAirlineRuleActivitiesId) {\n    activities {\n      id\n      created_at\n      activity_type\n      user_id\n      user_agency_id\n      err\n      transaction\n      user_info {\n        id\n        first_name\n        last_name\n        email\n      }\n      agency_info {\n        id\n        name\n        logo {\n          small_uri\n        }\n      }\n    }\n  }\n}\n    ";
export var useGetAirlineRuleActivitiesQuery = function (variables, options) {
    return useQuery(['getAirlineRuleActivities', variables], fetcherWithGraphQLClient(GetAirlineRuleActivitiesDocument, variables), options);
};
useGetAirlineRuleActivitiesQuery.getKey = function (variables) { return ['getAirlineRuleActivities', variables]; };
;
useGetAirlineRuleActivitiesQuery.fetcher = function (variables, options) { return fetcherWithGraphQLClient(GetAirlineRuleActivitiesDocument, variables, options); };
export var GetAirlineRuleConditionsConfigDocument = "\n    query GetAirlineRuleConditionsConfig {\n  getAirlineRuleComissionConditions {\n    commissions {\n      condition_type\n      operators {\n        operator_type\n        value_type\n      }\n    }\n    conditions {\n      condition_type\n      operators {\n        operator_type\n        value_type\n      }\n    }\n  }\n}\n    ";
export var useGetAirlineRuleConditionsConfigQuery = function (variables, options) {
    return useQuery(variables === undefined ? ['GetAirlineRuleConditionsConfig'] : ['GetAirlineRuleConditionsConfig', variables], fetcherWithGraphQLClient(GetAirlineRuleConditionsConfigDocument, variables), options);
};
useGetAirlineRuleConditionsConfigQuery.getKey = function (variables) { return variables === undefined ? ['GetAirlineRuleConditionsConfig'] : ['GetAirlineRuleConditionsConfig', variables]; };
;
useGetAirlineRuleConditionsConfigQuery.fetcher = function (variables, options) { return fetcherWithGraphQLClient(GetAirlineRuleConditionsConfigDocument, variables, options); };
export var GetAirlineRuleDocument = "\n    query GetAirlineRule($getAirlineRuleId: Float!) {\n  getAirlineRule(id: $getAirlineRuleId) {\n    airline_rule {\n      id\n      position\n      name\n      commission\n      endorsement\n      tourcode\n      created_at\n      deleted\n      valid_from\n      valid_to\n      conditions {\n        operator\n        type\n        values\n      }\n      commissions {\n        operator\n        type\n        values\n        percent\n      }\n    }\n  }\n}\n    ";
export var useGetAirlineRuleQuery = function (variables, options) {
    return useQuery(['GetAirlineRule', variables], fetcherWithGraphQLClient(GetAirlineRuleDocument, variables), options);
};
useGetAirlineRuleQuery.getKey = function (variables) { return ['GetAirlineRule', variables]; };
;
useGetAirlineRuleQuery.fetcher = function (variables, options) { return fetcherWithGraphQLClient(GetAirlineRuleDocument, variables, options); };
export var GetAirlineRulesSettingDocument = "\n    query GetAirlineRulesSetting($getAirlineRulesSettingId: Float!) {\n  getAirlineRulesSetting(id: $getAirlineRulesSettingId) {\n    airline_rules_setting {\n      id\n      airline\n      airline_info {\n        code\n        long_name\n        short_name\n      }\n      company_owner_id\n      pricing_rules {\n        tourcode\n        position\n        commission\n        name\n        id\n        last_update\n        is_expired\n        valid_from\n        valid_to\n        conditions {\n          type\n          operator\n          values\n        }\n        commissions {\n          type\n          operator\n          percent\n          values\n        }\n      }\n      created_at\n      deleted\n      enabled\n    }\n  }\n}\n    ";
export var useGetAirlineRulesSettingQuery = function (variables, options) {
    return useQuery(['GetAirlineRulesSetting', variables], fetcherWithGraphQLClient(GetAirlineRulesSettingDocument, variables), options);
};
useGetAirlineRulesSettingQuery.getKey = function (variables) { return ['GetAirlineRulesSetting', variables]; };
;
useGetAirlineRulesSettingQuery.fetcher = function (variables, options) { return fetcherWithGraphQLClient(GetAirlineRulesSettingDocument, variables, options); };
export var AirlinesDocument = "\n    query Airlines {\n  airlines {\n    short_name\n    long_name\n    code\n  }\n}\n    ";
export var useAirlinesQuery = function (variables, options) {
    return useQuery(variables === undefined ? ['Airlines'] : ['Airlines', variables], fetcherWithGraphQLClient(AirlinesDocument, variables), options);
};
useAirlinesQuery.getKey = function (variables) { return variables === undefined ? ['Airlines'] : ['Airlines', variables]; };
;
useAirlinesQuery.fetcher = function (variables, options) { return fetcherWithGraphQLClient(AirlinesDocument, variables, options); };
export var GetBranchDocument = "\n    query GetBranch($getBranchId: Float!) {\n  getBranch(id: $getBranchId) {\n    branch {\n      id\n      name\n      email\n      phone_number\n      description\n      fee_module_id\n      markup_module_id\n      search_module_id\n      enabled\n      created_by\n      created_at\n      last_update\n      timezone\n      language\n      fee_module_info {\n        id\n        name\n      }\n      markup_module_info {\n        id\n        name\n      }\n      search_module_info {\n        id\n        name\n      }\n    }\n  }\n}\n    ";
export var useGetBranchQuery = function (variables, options) {
    return useQuery(['GetBranch', variables], fetcherWithGraphQLClient(GetBranchDocument, variables), options);
};
useGetBranchQuery.getKey = function (variables) { return ['GetBranch', variables]; };
;
useGetBranchQuery.fetcher = function (variables, options) { return fetcherWithGraphQLClient(GetBranchDocument, variables, options); };
export var GetAgencyCommissionModuleActivitiesDocument = "\n    query GetAgencyCommissionModuleActivities($getAgencyCommissionModuleActivitiesId: Float!) {\n  getAgencyCommissionModuleActivities(id: $getAgencyCommissionModuleActivitiesId) {\n    activity_type\n    id\n    created_at\n    agency_id\n    commission_module_id\n    user_id\n    err\n    transaction\n    agency_info {\n      name\n    }\n    user_info {\n      first_name\n      last_name\n      email\n      id\n    }\n  }\n}\n    ";
export var useGetAgencyCommissionModuleActivitiesQuery = function (variables, options) {
    return useQuery(['GetAgencyCommissionModuleActivities', variables], fetcherWithGraphQLClient(GetAgencyCommissionModuleActivitiesDocument, variables), options);
};
useGetAgencyCommissionModuleActivitiesQuery.getKey = function (variables) { return ['GetAgencyCommissionModuleActivities', variables]; };
;
useGetAgencyCommissionModuleActivitiesQuery.fetcher = function (variables, options) { return fetcherWithGraphQLClient(GetAgencyCommissionModuleActivitiesDocument, variables, options); };
export var GetCompanyDocument = "\n    query GetCompany {\n  getCompany {\n    name\n    address\n    backoffice_email\n    country_code\n    currency\n    email\n    error_notification_email\n    phone\n    postal_code\n    default_minimum_agency_fee_amount\n    capability {\n      has_cnpj\n      has_pix\n    }\n  }\n}\n    ";
export var useGetCompanyQuery = function (variables, options) {
    return useQuery(variables === undefined ? ['GetCompany'] : ['GetCompany', variables], fetcherWithGraphQLClient(GetCompanyDocument, variables), options);
};
useGetCompanyQuery.getKey = function (variables) { return variables === undefined ? ['GetCompany'] : ['GetCompany', variables]; };
;
useGetCompanyQuery.fetcher = function (variables, options) { return fetcherWithGraphQLClient(GetCompanyDocument, variables, options); };
export var GetAgencyConsolidFeeModuleActivitiesDocument = "\n    query GetAgencyConsolidFeeModuleActivities($getAgencyConsolidFeeModuleActivitiesId: Float!) {\n  getAgencyConsolidFeeModuleActivities(\n    id: $getAgencyConsolidFeeModuleActivitiesId\n  ) {\n    activity_type\n    agency_consolid_fee_module_id\n    agency_id\n    agency_info {\n      name\n    }\n    created_at\n    err\n    id\n    transaction\n    user_id\n    user_info {\n      first_name\n      last_name\n      email\n      id\n    }\n  }\n}\n    ";
export var useGetAgencyConsolidFeeModuleActivitiesQuery = function (variables, options) {
    return useQuery(['GetAgencyConsolidFeeModuleActivities', variables], fetcherWithGraphQLClient(GetAgencyConsolidFeeModuleActivitiesDocument, variables), options);
};
useGetAgencyConsolidFeeModuleActivitiesQuery.getKey = function (variables) { return ['GetAgencyConsolidFeeModuleActivities', variables]; };
;
useGetAgencyConsolidFeeModuleActivitiesQuery.fetcher = function (variables, options) { return fetcherWithGraphQLClient(GetAgencyConsolidFeeModuleActivitiesDocument, variables, options); };
export var GetAgencyConsolidFeeModulesDocument = "\n    query GetAgencyConsolidFeeModules {\n  getAgencyConsolidFeeModules {\n    id\n    name\n    description\n    created_at\n    last_update\n  }\n}\n    ";
export var useGetAgencyConsolidFeeModulesQuery = function (variables, options) {
    return useQuery(variables === undefined ? ['GetAgencyConsolidFeeModules'] : ['GetAgencyConsolidFeeModules', variables], fetcherWithGraphQLClient(GetAgencyConsolidFeeModulesDocument, variables), options);
};
useGetAgencyConsolidFeeModulesQuery.getKey = function (variables) { return variables === undefined ? ['GetAgencyConsolidFeeModules'] : ['GetAgencyConsolidFeeModules', variables]; };
;
useGetAgencyConsolidFeeModulesQuery.fetcher = function (variables, options) { return fetcherWithGraphQLClient(GetAgencyConsolidFeeModulesDocument, variables, options); };
export var GetMarkupModuleDocument = "\n    query GetMarkupModule($getMarkupModuleId: Float!) {\n  getMarkupModule(id: $getMarkupModuleId) {\n    markup_module {\n      id\n      name\n      description\n      tourcode_backoffice\n      airline_markup_rules {\n        id\n        markup_module_id\n        airline_code\n        airline_info {\n          code\n          short_name\n          long_name\n        }\n        provider_type\n        provider_info {\n          name\n          type\n        }\n        account_code\n        markup_percent\n        created_at\n        created_by\n        last_update\n      }\n      enabled\n      created_at\n      created_by\n      last_update\n    }\n  }\n}\n    ";
export var useGetMarkupModuleQuery = function (variables, options) {
    return useQuery(['GetMarkupModule', variables], fetcherWithGraphQLClient(GetMarkupModuleDocument, variables), options);
};
useGetMarkupModuleQuery.getKey = function (variables) { return ['GetMarkupModule', variables]; };
;
useGetMarkupModuleQuery.fetcher = function (variables, options) { return fetcherWithGraphQLClient(GetMarkupModuleDocument, variables, options); };
export var GetPassengerProfileActivitiesDocument = "\n    query GetPassengerProfileActivities($filter: ListPassengerProfileActivitiesFilter!) {\n  listPassengerProfileActivities(filter: $filter) {\n    activities {\n      id\n      created_at\n      activity_type\n      user_id\n      err\n      transaction\n      user_info {\n        id\n        first_name\n        last_name\n        email\n      }\n    }\n  }\n}\n    ";
export var useGetPassengerProfileActivitiesQuery = function (variables, options) {
    return useQuery(['GetPassengerProfileActivities', variables], fetcherWithGraphQLClient(GetPassengerProfileActivitiesDocument, variables), options);
};
useGetPassengerProfileActivitiesQuery.getKey = function (variables) { return ['GetPassengerProfileActivities', variables]; };
;
useGetPassengerProfileActivitiesQuery.fetcher = function (variables, options) { return fetcherWithGraphQLClient(GetPassengerProfileActivitiesDocument, variables, options); };
export var GetPassengerProfileDocument = "\n    query GetPassengerProfile($filter: GetPassengerProfileFilter!) {\n  getPassengerProfile(filter: $filter) {\n    passenger_profile {\n      id\n      agency_id\n      first_name\n      middle_name\n      last_name\n      birth_date\n      nationality\n      phone_number\n      email\n      pax_type\n      gender_type\n      observations\n      status\n      created_by\n      created_at\n      redress\n      created_by_info {\n        id\n        first_name\n        last_name\n        email\n      }\n      documents {\n        unique_identifier\n        type\n        issuing_country_code\n        expires_at\n      }\n      frequent_flyers {\n        id\n        code\n        airline\n        airline_name\n      }\n    }\n  }\n}\n    ";
export var useGetPassengerProfileQuery = function (variables, options) {
    return useQuery(['GetPassengerProfile', variables], fetcherWithGraphQLClient(GetPassengerProfileDocument, variables), options);
};
useGetPassengerProfileQuery.getKey = function (variables) { return ['GetPassengerProfile', variables]; };
;
useGetPassengerProfileQuery.fetcher = function (variables, options) { return fetcherWithGraphQLClient(GetPassengerProfileDocument, variables, options); };
export var GetRolesToApplyDocument = "\n    query getRolesToApply {\n  getRolesToApply\n}\n    ";
export var useGetRolesToApplyQuery = function (variables, options) {
    return useQuery(variables === undefined ? ['getRolesToApply'] : ['getRolesToApply', variables], fetcherWithGraphQLClient(GetRolesToApplyDocument, variables), options);
};
useGetRolesToApplyQuery.getKey = function (variables) { return variables === undefined ? ['getRolesToApply'] : ['getRolesToApply', variables]; };
;
useGetRolesToApplyQuery.fetcher = function (variables, options) { return fetcherWithGraphQLClient(GetRolesToApplyDocument, variables, options); };
export var GetSearchModuleActivitiesDocument = "\n    query GetSearchModuleActivities($searchModuleId: Float!) {\n  listSearchModuleActivities(search_module_id: $searchModuleId) {\n    activities {\n      id\n      created_at\n      activity_type\n      user_id\n      user_agency_id\n      err\n      transaction\n      user_info {\n        id\n        first_name\n        last_name\n        email\n      }\n      agency_info {\n        id\n        name\n        logo {\n          mime_type\n          small_uri\n          medium_uri\n          large_uri\n          original_uri\n        }\n      }\n    }\n  }\n}\n    ";
export var useGetSearchModuleActivitiesQuery = function (variables, options) {
    return useQuery(['GetSearchModuleActivities', variables], fetcherWithGraphQLClient(GetSearchModuleActivitiesDocument, variables), options);
};
useGetSearchModuleActivitiesQuery.getKey = function (variables) { return ['GetSearchModuleActivities', variables]; };
;
useGetSearchModuleActivitiesQuery.fetcher = function (variables, options) { return fetcherWithGraphQLClient(GetSearchModuleActivitiesDocument, variables, options); };
export var GetSearchModuleAssociatedAgenciesDocument = "\n    query GetSearchModuleAssociatedAgencies($searchModuleId: Float!) {\n  listSearchModuleAssociatedAgencies(search_module_id: $searchModuleId) {\n    associated_agencies {\n      agency_id\n      agency_info {\n        id\n        name\n        logo {\n          mime_type\n          small_uri\n          medium_uri\n          large_uri\n          original_uri\n        }\n      }\n    }\n  }\n}\n    ";
export var useGetSearchModuleAssociatedAgenciesQuery = function (variables, options) {
    return useQuery(['GetSearchModuleAssociatedAgencies', variables], fetcherWithGraphQLClient(GetSearchModuleAssociatedAgenciesDocument, variables), options);
};
useGetSearchModuleAssociatedAgenciesQuery.getKey = function (variables) { return ['GetSearchModuleAssociatedAgencies', variables]; };
;
useGetSearchModuleAssociatedAgenciesQuery.fetcher = function (variables, options) { return fetcherWithGraphQLClient(GetSearchModuleAssociatedAgenciesDocument, variables, options); };
export var GetSearchModuleDetailDocument = "\n    query GetSearchModuleDetail($getSearchModuleId: Float!) {\n  getSearchModule(id: $getSearchModuleId) {\n    search_module {\n      description\n      name\n      id\n      rules {\n        name\n        last_update\n        id\n      }\n    }\n  }\n}\n    ";
export var useGetSearchModuleDetailQuery = function (variables, options) {
    return useQuery(['GetSearchModuleDetail', variables], fetcherWithGraphQLClient(GetSearchModuleDetailDocument, variables), options);
};
useGetSearchModuleDetailQuery.getKey = function (variables) { return ['GetSearchModuleDetail', variables]; };
;
useGetSearchModuleDetailQuery.fetcher = function (variables, options) { return fetcherWithGraphQLClient(GetSearchModuleDetailDocument, variables, options); };
export var GetSearchModuleRuleActivitiesDocument = "\n    query GetSearchModuleRuleActivities($searchModuleRuleId: Float!) {\n  listSearchModuleRuleActivities(search_module_rule_id: $searchModuleRuleId) {\n    activities {\n      id\n      created_at\n      activity_type\n      user_id\n      user_agency_id\n      err\n      transaction\n      user_info {\n        id\n        first_name\n        last_name\n        email\n      }\n      agency_info {\n        id\n        name\n        logo {\n          small_uri\n        }\n      }\n    }\n  }\n}\n    ";
export var useGetSearchModuleRuleActivitiesQuery = function (variables, options) {
    return useQuery(['GetSearchModuleRuleActivities', variables], fetcherWithGraphQLClient(GetSearchModuleRuleActivitiesDocument, variables), options);
};
useGetSearchModuleRuleActivitiesQuery.getKey = function (variables) { return ['GetSearchModuleRuleActivities', variables]; };
;
useGetSearchModuleRuleActivitiesQuery.fetcher = function (variables, options) { return fetcherWithGraphQLClient(GetSearchModuleRuleActivitiesDocument, variables, options); };
export var GetSearchModuleConditionsDocument = "\n    query GetSearchModuleConditions {\n  getSearchModuleConditions {\n    conditions {\n      condition_type\n      operators {\n        operator_type\n        value_type\n      }\n    }\n  }\n}\n    ";
export var useGetSearchModuleConditionsQuery = function (variables, options) {
    return useQuery(variables === undefined ? ['GetSearchModuleConditions'] : ['GetSearchModuleConditions', variables], fetcherWithGraphQLClient(GetSearchModuleConditionsDocument, variables), options);
};
useGetSearchModuleConditionsQuery.getKey = function (variables) { return variables === undefined ? ['GetSearchModuleConditions'] : ['GetSearchModuleConditions', variables]; };
;
useGetSearchModuleConditionsQuery.fetcher = function (variables, options) { return fetcherWithGraphQLClient(GetSearchModuleConditionsDocument, variables, options); };
export var GetSearchModuleRuleDetailDocument = "\n    query GetSearchModuleRuleDetail($getSearchModuleRuleId: Float!) {\n  getSearchModuleRule(id: $getSearchModuleRuleId) {\n    rule {\n      id\n      name\n      last_update\n      business_agreements {\n        name\n      }\n      conditions {\n        type\n        operator\n        values\n      }\n      business_agreements {\n        name\n      }\n    }\n  }\n}\n    ";
export var useGetSearchModuleRuleDetailQuery = function (variables, options) {
    return useQuery(['GetSearchModuleRuleDetail', variables], fetcherWithGraphQLClient(GetSearchModuleRuleDetailDocument, variables), options);
};
useGetSearchModuleRuleDetailQuery.getKey = function (variables) { return ['GetSearchModuleRuleDetail', variables]; };
;
useGetSearchModuleRuleDetailQuery.fetcher = function (variables, options) { return fetcherWithGraphQLClient(GetSearchModuleRuleDetailDocument, variables, options); };
export var GetSearchModuleRuleDocument = "\n    query GetSearchModuleRule($getSearchModuleRuleId: Float!) {\n  getSearchModuleRule(id: $getSearchModuleRuleId) {\n    rule {\n      id\n      name\n      business_agreements {\n        name\n        credential_id\n        pcc\n        included_airlines\n        excluded_airlines\n        account_codes\n        ptcs {\n          pax_type\n          equivalent_code\n        }\n        with_branded_fares\n        additional_nonstops\n        with_additional_fares\n        provider_max_offers\n        fare_type\n        app_sources\n        has_du\n      }\n      conditions {\n        type\n        operator\n        values\n      }\n    }\n  }\n}\n    ";
export var useGetSearchModuleRuleQuery = function (variables, options) {
    return useQuery(['GetSearchModuleRule', variables], fetcherWithGraphQLClient(GetSearchModuleRuleDocument, variables), options);
};
useGetSearchModuleRuleQuery.getKey = function (variables) { return ['GetSearchModuleRule', variables]; };
;
useGetSearchModuleRuleQuery.fetcher = function (variables, options) { return fetcherWithGraphQLClient(GetSearchModuleRuleDocument, variables, options); };
export var GetSearchModuleRulesDocument = "\n    query GetSearchModuleRules($getSearchModuleId: Float!) {\n  getSearchModule(id: $getSearchModuleId) {\n    search_module {\n      id\n      rules {\n        id\n        name\n        business_agreements {\n          name\n          credential_id\n          pcc\n          included_airlines\n          excluded_airlines\n          account_codes\n          ptcs {\n            pax_type\n            equivalent_code\n          }\n          with_branded_fares\n          additional_nonstops\n          with_additional_fares\n          provider_max_offers\n          fare_type\n          app_sources\n          has_du\n        }\n        conditions {\n          type\n          operator\n          values\n        }\n      }\n    }\n  }\n}\n    ";
export var useGetSearchModuleRulesQuery = function (variables, options) {
    return useQuery(['GetSearchModuleRules', variables], fetcherWithGraphQLClient(GetSearchModuleRulesDocument, variables), options);
};
useGetSearchModuleRulesQuery.getKey = function (variables) { return ['GetSearchModuleRules', variables]; };
;
useGetSearchModuleRulesQuery.fetcher = function (variables, options) { return fetcherWithGraphQLClient(GetSearchModuleRulesDocument, variables, options); };
export var GetSearchModuleDocument = "\n    query GetSearchModule($getSearchModuleId: Float!) {\n  getSearchModule(id: $getSearchModuleId) {\n    search_module {\n      id\n      name\n      description\n    }\n  }\n}\n    ";
export var useGetSearchModuleQuery = function (variables, options) {
    return useQuery(['GetSearchModule', variables], fetcherWithGraphQLClient(GetSearchModuleDocument, variables), options);
};
useGetSearchModuleQuery.getKey = function (variables) { return ['GetSearchModule', variables]; };
;
useGetSearchModuleQuery.fetcher = function (variables, options) { return fetcherWithGraphQLClient(GetSearchModuleDocument, variables, options); };
export var GetUserActivitiesDocument = "\n    query GetUserActivities($getUserActivitiesId: Float!) {\n  getUserActivities(id: $getUserActivitiesId) {\n    activity_type\n    id\n    created_at\n    agency_id\n    user_agency_id\n    user_id\n    err\n    transaction\n    user_info {\n      first_name\n      last_name\n    }\n  }\n}\n    ";
export var useGetUserActivitiesQuery = function (variables, options) {
    return useQuery(['GetUserActivities', variables], fetcherWithGraphQLClient(GetUserActivitiesDocument, variables), options);
};
useGetUserActivitiesQuery.getKey = function (variables) { return ['GetUserActivities', variables]; };
;
useGetUserActivitiesQuery.fetcher = function (variables, options) { return fetcherWithGraphQLClient(GetUserActivitiesDocument, variables, options); };
export var GetUserDocument = "\n    query GetUser($getUserId: Float) {\n  getUser(id: $getUserId) {\n    id\n    first_name\n    middle_name\n    last_name\n    email\n    has_mfa\n    phone_number\n    roles\n    disabled\n    language\n    avatar {\n      small_uri\n    }\n    agency {\n      name\n      logo {\n        small_uri\n      }\n    }\n  }\n}\n    ";
export var useGetUserQuery = function (variables, options) {
    return useQuery(variables === undefined ? ['GetUser'] : ['GetUser', variables], fetcherWithGraphQLClient(GetUserDocument, variables), options);
};
useGetUserQuery.getKey = function (variables) { return variables === undefined ? ['GetUser'] : ['GetUser', variables]; };
;
useGetUserQuery.fetcher = function (variables, options) { return fetcherWithGraphQLClient(GetUserDocument, variables, options); };
export var GetUsersDocument = "\n    query GetUsers($filter: UserFilter) {\n  getUsers(filter: $filter) {\n    id\n    email\n    first_name\n    middle_name\n    last_name\n    last_activity\n    agency_id\n    disabled\n  }\n}\n    ";
export var useGetUsersQuery = function (variables, options) {
    return useQuery(variables === undefined ? ['GetUsers'] : ['GetUsers', variables], fetcherWithGraphQLClient(GetUsersDocument, variables), options);
};
useGetUsersQuery.getKey = function (variables) { return variables === undefined ? ['GetUsers'] : ['GetUsers', variables]; };
;
useGetUsersQuery.fetcher = function (variables, options) { return fetcherWithGraphQLClient(GetUsersDocument, variables, options); };
export var ListAgenciesDocument = "\n    query ListAgencies {\n  listAgencies {\n    agencies {\n      id\n      name\n      created_at\n      enabled\n      crm_company_id\n      last_activity\n      logo {\n        medium_uri\n      }\n      created_by_info {\n        first_name\n      }\n      branch_info {\n        id\n        name\n        description\n      }\n    }\n    dictionaries {\n      branches {\n        id\n        name\n        description\n      }\n    }\n  }\n}\n    ";
export var useListAgenciesQuery = function (variables, options) {
    return useQuery(variables === undefined ? ['ListAgencies'] : ['ListAgencies', variables], fetcherWithGraphQLClient(ListAgenciesDocument, variables), options);
};
useListAgenciesQuery.getKey = function (variables) { return variables === undefined ? ['ListAgencies'] : ['ListAgencies', variables]; };
;
useListAgenciesQuery.fetcher = function (variables, options) { return fetcherWithGraphQLClient(ListAgenciesDocument, variables, options); };
export var ListAirlineRulesSettingsDocument = "\n    query ListAirlineRulesSettings($airline: String!, $companyOwnerId: String!) {\n  listAirlineRulesSettings(airline: $airline, company_owner_id: $companyOwnerId) {\n    airline_rules_settings {\n      id\n      airline\n      company_owner_id\n      last_update\n      created_at\n    }\n  }\n}\n    ";
export var useListAirlineRulesSettingsQuery = function (variables, options) {
    return useQuery(['ListAirlineRulesSettings', variables], fetcherWithGraphQLClient(ListAirlineRulesSettingsDocument, variables), options);
};
useListAirlineRulesSettingsQuery.getKey = function (variables) { return ['ListAirlineRulesSettings', variables]; };
;
useListAirlineRulesSettingsQuery.fetcher = function (variables, options) { return fetcherWithGraphQLClient(ListAirlineRulesSettingsDocument, variables, options); };
export var ListBranchActivitiesDocument = "\n    query ListBranchActivities($listBranchActivitiesId: Float!) {\n  listBranchActivities(id: $listBranchActivitiesId) {\n    activities {\n      id\n      created_at\n      activity_type\n      user_id\n      user_agency_id\n      err\n      transaction\n      user_info {\n        id\n        first_name\n        last_name\n        email\n      }\n      agency_info {\n        id\n        name\n        logo {\n          small_uri\n          medium_uri\n        }\n      }\n    }\n  }\n}\n    ";
export var useListBranchActivitiesQuery = function (variables, options) {
    return useQuery(['ListBranchActivities', variables], fetcherWithGraphQLClient(ListBranchActivitiesDocument, variables), options);
};
useListBranchActivitiesQuery.getKey = function (variables) { return ['ListBranchActivities', variables]; };
;
useListBranchActivitiesQuery.fetcher = function (variables, options) { return fetcherWithGraphQLClient(ListBranchActivitiesDocument, variables, options); };
export var ListBranchAssociatedAgenciesDocument = "\n    query ListBranchAssociatedAgencies($branchId: Float!) {\n  listBranchAssociatedAgencies(branch_id: $branchId) {\n    associated_agencies {\n      agency_id\n      agency_info {\n        logo {\n          small_uri\n          medium_uri\n        }\n        id\n        name\n      }\n    }\n  }\n}\n    ";
export var useListBranchAssociatedAgenciesQuery = function (variables, options) {
    return useQuery(['ListBranchAssociatedAgencies', variables], fetcherWithGraphQLClient(ListBranchAssociatedAgenciesDocument, variables), options);
};
useListBranchAssociatedAgenciesQuery.getKey = function (variables) { return ['ListBranchAssociatedAgencies', variables]; };
;
useListBranchAssociatedAgenciesQuery.fetcher = function (variables, options) { return fetcherWithGraphQLClient(ListBranchAssociatedAgenciesDocument, variables, options); };
export var ListBranchesDocument = "\n    query ListBranches {\n  listBranches {\n    branches {\n      id\n      name\n      description\n      email\n      phone_number\n      fee_module_id\n      markup_module_id\n      search_module_id\n      enabled\n      created_by\n      created_at\n      last_update\n      timezone\n      language\n    }\n  }\n}\n    ";
export var useListBranchesQuery = function (variables, options) {
    return useQuery(variables === undefined ? ['ListBranches'] : ['ListBranches', variables], fetcherWithGraphQLClient(ListBranchesDocument, variables), options);
};
useListBranchesQuery.getKey = function (variables) { return variables === undefined ? ['ListBranches'] : ['ListBranches', variables]; };
;
useListBranchesQuery.fetcher = function (variables, options) { return fetcherWithGraphQLClient(ListBranchesDocument, variables, options); };
export var ListCountriesDocument = "\n    query ListCountries {\n  listCountries {\n    code\n    name\n  }\n}\n    ";
export var useListCountriesQuery = function (variables, options) {
    return useQuery(variables === undefined ? ['ListCountries'] : ['ListCountries', variables], fetcherWithGraphQLClient(ListCountriesDocument, variables), options);
};
useListCountriesQuery.getKey = function (variables) { return variables === undefined ? ['ListCountries'] : ['ListCountries', variables]; };
;
useListCountriesQuery.fetcher = function (variables, options) { return fetcherWithGraphQLClient(ListCountriesDocument, variables, options); };
export var ListMarkupModuleActivitiesDocument = "\n    query ListMarkupModuleActivities($listMarkupModuleActivitiesId: Float!) {\n  listMarkupModuleActivities(id: $listMarkupModuleActivitiesId) {\n    activities {\n      id\n      created_at\n      activity_type\n      err\n      user_info {\n        id\n        first_name\n        last_name\n      }\n    }\n  }\n}\n    ";
export var useListMarkupModuleActivitiesQuery = function (variables, options) {
    return useQuery(['ListMarkupModuleActivities', variables], fetcherWithGraphQLClient(ListMarkupModuleActivitiesDocument, variables), options);
};
useListMarkupModuleActivitiesQuery.getKey = function (variables) { return ['ListMarkupModuleActivities', variables]; };
;
useListMarkupModuleActivitiesQuery.fetcher = function (variables, options) { return fetcherWithGraphQLClient(ListMarkupModuleActivitiesDocument, variables, options); };
export var ListMarkupModuleAssociatedAgenciesDocument = "\n    query ListMarkupModuleAssociatedAgencies($markupModuleId: Float!) {\n  listMarkupModuleAssociatedAgencies(markup_module_id: $markupModuleId) {\n    associated_agencies {\n      agency_info {\n        id\n        name\n        logo {\n          small_uri\n        }\n      }\n    }\n  }\n}\n    ";
export var useListMarkupModuleAssociatedAgenciesQuery = function (variables, options) {
    return useQuery(['ListMarkupModuleAssociatedAgencies', variables], fetcherWithGraphQLClient(ListMarkupModuleAssociatedAgenciesDocument, variables), options);
};
useListMarkupModuleAssociatedAgenciesQuery.getKey = function (variables) { return ['ListMarkupModuleAssociatedAgencies', variables]; };
;
useListMarkupModuleAssociatedAgenciesQuery.fetcher = function (variables, options) { return fetcherWithGraphQLClient(ListMarkupModuleAssociatedAgenciesDocument, variables, options); };
export var ListMarkupModulesDocument = "\n    query ListMarkupModules {\n  listMarkupModules {\n    markup_modules {\n      id\n      name\n      description\n      tourcode_backoffice\n      created_at\n      last_update\n    }\n  }\n}\n    ";
export var useListMarkupModulesQuery = function (variables, options) {
    return useQuery(variables === undefined ? ['ListMarkupModules'] : ['ListMarkupModules', variables], fetcherWithGraphQLClient(ListMarkupModulesDocument, variables), options);
};
useListMarkupModulesQuery.getKey = function (variables) { return variables === undefined ? ['ListMarkupModules'] : ['ListMarkupModules', variables]; };
;
useListMarkupModulesQuery.fetcher = function (variables, options) { return fetcherWithGraphQLClient(ListMarkupModulesDocument, variables, options); };
export var ListPriceBreakdownDocument = "\n    query ListPriceBreakdown {\n  listPriceBreakdown\n}\n    ";
export var useListPriceBreakdownQuery = function (variables, options) {
    return useQuery(variables === undefined ? ['ListPriceBreakdown'] : ['ListPriceBreakdown', variables], fetcherWithGraphQLClient(ListPriceBreakdownDocument, variables), options);
};
useListPriceBreakdownQuery.getKey = function (variables) { return variables === undefined ? ['ListPriceBreakdown'] : ['ListPriceBreakdown', variables]; };
;
useListPriceBreakdownQuery.fetcher = function (variables, options) { return fetcherWithGraphQLClient(ListPriceBreakdownDocument, variables, options); };
export var ListProvidersDocument = "\n    query ListProviders {\n  listProviders {\n    type\n    name\n  }\n}\n    ";
export var useListProvidersQuery = function (variables, options) {
    return useQuery(variables === undefined ? ['ListProviders'] : ['ListProviders', variables], fetcherWithGraphQLClient(ListProvidersDocument, variables), options);
};
useListProvidersQuery.getKey = function (variables) { return variables === undefined ? ['ListProviders'] : ['ListProviders', variables]; };
;
useListProvidersQuery.fetcher = function (variables, options) { return fetcherWithGraphQLClient(ListProvidersDocument, variables, options); };
export var ListSearchModulesDocument = "\n    query ListSearchModules {\n  listSearchModules {\n    search_modules {\n      id\n      name\n      description\n      last_update\n      created_at\n    }\n  }\n}\n    ";
export var useListSearchModulesQuery = function (variables, options) {
    return useQuery(variables === undefined ? ['ListSearchModules'] : ['ListSearchModules', variables], fetcherWithGraphQLClient(ListSearchModulesDocument, variables), options);
};
useListSearchModulesQuery.getKey = function (variables) { return variables === undefined ? ['ListSearchModules'] : ['ListSearchModules', variables]; };
;
useListSearchModulesQuery.fetcher = function (variables, options) { return fetcherWithGraphQLClient(ListSearchModulesDocument, variables, options); };
export var SaveMarkupModuleDocument = "\n    mutation SaveMarkupModule($markupModule: MarkupModuleValues!) {\n  saveMarkupModule(markup_module: $markupModule)\n}\n    ";
export var useSaveMarkupModuleMutation = function (options) {
    return useMutation(['SaveMarkupModule'], function (variables) { return fetcherWithGraphQLClient(SaveMarkupModuleDocument, variables)(); }, options);
};
useSaveMarkupModuleMutation.getKey = function () { return ['SaveMarkupModule']; };
useSaveMarkupModuleMutation.fetcher = function (variables, options) { return fetcherWithGraphQLClient(SaveMarkupModuleDocument, variables, options); };
export var SearchAirlinesDocument = "\n    query SearchAirlines($query: String!) {\n  searchAirlines(query: $query) {\n    code\n    short_name\n    long_name\n  }\n}\n    ";
export var useSearchAirlinesQuery = function (variables, options) {
    return useQuery(['SearchAirlines', variables], fetcherWithGraphQLClient(SearchAirlinesDocument, variables), options);
};
useSearchAirlinesQuery.getKey = function (variables) { return ['SearchAirlines', variables]; };
;
useSearchAirlinesQuery.fetcher = function (variables, options) { return fetcherWithGraphQLClient(SearchAirlinesDocument, variables, options); };
export var SearchCitiesDocument = "\n    query SearchCities($query: String!) {\n  searchCities(query: $query) {\n    code\n    name\n    country_code\n  }\n}\n    ";
export var useSearchCitiesQuery = function (variables, options) {
    return useQuery(['SearchCities', variables], fetcherWithGraphQLClient(SearchCitiesDocument, variables), options);
};
useSearchCitiesQuery.getKey = function (variables) { return ['SearchCities', variables]; };
;
useSearchCitiesQuery.fetcher = function (variables, options) { return fetcherWithGraphQLClient(SearchCitiesDocument, variables, options); };
export var SearchContinentsDocument = "\n    query SearchContinents($query: String!) {\n  searchContinents(query: $query) {\n    code\n    name\n  }\n}\n    ";
export var useSearchContinentsQuery = function (variables, options) {
    return useQuery(['SearchContinents', variables], fetcherWithGraphQLClient(SearchContinentsDocument, variables), options);
};
useSearchContinentsQuery.getKey = function (variables) { return ['SearchContinents', variables]; };
;
useSearchContinentsQuery.fetcher = function (variables, options) { return fetcherWithGraphQLClient(SearchContinentsDocument, variables, options); };
export var SearchCountriesDocument = "\n    query SearchCountries($query: String!) {\n  searchCountries(query: $query) {\n    code\n    name\n    continent_code\n  }\n}\n    ";
export var useSearchCountriesQuery = function (variables, options) {
    return useQuery(['SearchCountries', variables], fetcherWithGraphQLClient(SearchCountriesDocument, variables), options);
};
useSearchCountriesQuery.getKey = function (variables) { return ['SearchCountries', variables]; };
;
useSearchCountriesQuery.fetcher = function (variables, options) { return fetcherWithGraphQLClient(SearchCountriesDocument, variables, options); };
export var SearchLocationsDocument = "\n    query SearchLocations($query: String!) {\n  searchLocations(query: $query) {\n    code\n    name\n    city_name\n    country_name\n  }\n}\n    ";
export var useSearchLocationsQuery = function (variables, options) {
    return useQuery(['SearchLocations', variables], fetcherWithGraphQLClient(SearchLocationsDocument, variables), options);
};
useSearchLocationsQuery.getKey = function (variables) { return ['SearchLocations', variables]; };
;
useSearchLocationsQuery.fetcher = function (variables, options) { return fetcherWithGraphQLClient(SearchLocationsDocument, variables, options); };
export var SearchSearchModulesDocument = "\n    query SearchSearchModules($query: String!) {\n  searchSearchModules(query: $query) {\n    search_modules {\n      last_update\n      id\n      name\n      description\n    }\n  }\n}\n    ";
export var useSearchSearchModulesQuery = function (variables, options) {
    return useQuery(['SearchSearchModules', variables], fetcherWithGraphQLClient(SearchSearchModulesDocument, variables), options);
};
useSearchSearchModulesQuery.getKey = function (variables) { return ['SearchSearchModules', variables]; };
;
useSearchSearchModulesQuery.fetcher = function (variables, options) { return fetcherWithGraphQLClient(SearchSearchModulesDocument, variables, options); };
