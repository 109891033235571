var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// en
import agenciesEn from './languages/en_US/agencies.json';
import airlineCommissionsEn from './languages/en_US/airlineCommissions.json';
import airlineCommissionsRuleEn from './languages/en_US/airlineCommissionsRule.json';
import branchesEn from './languages/en_US/branches.json';
import buttonsEn from './languages/en_US/buttons.json';
import constantsEn from './languages/en_US/constants.json';
import dialogEn from './languages/en_US/dialog.json';
import errorsEn from './languages/en_US/errors.json';
import formEn from './languages/en_US/form.json';
import layoutEn from './languages/en_US/layout.json';
import pageEn from './languages/en_US/page.json';
import routesEn from './languages/en_US/routes.json';
import ruleConditionEn from './languages/en_US/ruleCondition.json';
import searchModulesEn from './languages/en_US/searchModules.json';
import sharedEn from './languages/en_US/shared.json';
import validationEn from './languages/en_US/validation.json';
// pt-BR
import agenciesPtBr from './languages/pt_BR/agencies.json';
import airlineCommissionsPtBr from './languages/pt_BR/airlineCommissions.json';
import airlineCommissionsRulePtBr from './languages/pt_BR/airlineCommissionsRule.json';
import branchesPtBr from './languages/pt_BR/branches.json';
import buttonsPtBr from './languages/pt_BR/buttons.json';
import constantsPtBr from './languages/pt_BR/constants.json';
import dialogPtBr from './languages/pt_BR/dialog.json';
import errorsPtBr from './languages/pt_BR/errors.json';
import formPtBr from './languages/pt_BR/form.json';
import layoutPtBr from './languages/pt_BR/layout.json';
import pagePtBr from './languages/pt_BR/page.json';
import routesPtBr from './languages/pt_BR/routes.json';
import ruleConditionPtBr from './languages/pt_BR/ruleCondition.json';
import searchModulesPtBr from './languages/pt_BR/searchModules.json';
import sharedPtBr from './languages/pt_BR/shared.json';
import validationPtBr from './languages/pt_BR/validation.json';
export var messages = {
    en_US: {
        translation: __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, agenciesEn), constantsEn), dialogEn), errorsEn), formEn), layoutEn), routesEn), sharedEn), validationEn), buttonsEn), airlineCommissionsEn), ruleConditionEn), pageEn), branchesEn), searchModulesEn), airlineCommissionsRuleEn),
    },
    pt_BR: {
        translation: __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, agenciesPtBr), constantsPtBr), dialogPtBr), errorsPtBr), formPtBr), layoutPtBr), routesPtBr), sharedPtBr), validationPtBr), buttonsPtBr), airlineCommissionsPtBr), ruleConditionPtBr), pagePtBr), branchesPtBr), searchModulesPtBr), airlineCommissionsRulePtBr),
    },
};
