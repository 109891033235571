import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { lazily } from 'react-lazily';
import { Outlet, Route, createBrowserRouter, createRoutesFromElements, } from 'react-router-dom';
import { AppProviders } from '@/AppProviders';
import { BranchesRoutes } from '@/ui/modules/branches/routes';
import { ListContextProvider } from '@/ui/modules/shared/components/ListContext';
import { BlockedAccess, RouteHandler } from '@agentguru/auth';
import { Toaster, WaitModule } from '@agentguru/components';
import { AuthProviderWithRedirectCallback as AuthProvider } from './components/AuthProviderWithRedirectCallback';
import { ShellProtected } from './components/ShellProtected';
import { TrackNavigation } from './components/TrackNavigation';
import { routes } from './constants/routes';
var AgenciesRoutes = lazily(function () { return import('@/ui/modules/agency/routes'); }).AgenciesRoutes;
var CommissionRoutes = lazily(function () { return import('@/ui/modules/commission/routes'); }).CommissionRoutes;
var ServiceFeeRoutes = lazily(function () { return import('@/ui/modules/service-fee/routes'); }).ServiceFeeRoutes;
var AirlinesCommissionsRoutes = lazily(function () { return import('@/ui/modules/airlines-commissions/routes'); }).AirlinesCommissionsRoutes;
var MarkupRoutes = lazily(function () { return import('@/ui/modules/markup/routes'); }).MarkupRoutes;
var SearchModulesRoutes = lazily(function () { return import('@/ui/modules/search-module/routes'); }).SearchModulesRoutes;
function Root() {
    return (_jsxs(_Fragment, { children: [_jsx(Toaster, {}), _jsx(AppProviders, { children: _jsx(Outlet, {}) }), _jsx(TrackNavigation, {})] }));
}
export var router = createBrowserRouter(createRoutesFromElements(_jsxs(Route, { path: "/", element: _jsx(AuthProvider, { children: _jsx(Root, {}) }), children: [_jsx(Route, { index: true, element: _jsx(RouteHandler, { defaultPath: routes.agencies, accessDeniedPath: routes.accessDenied }) }), _jsxs(Route, { path: "/*", element: _jsx(WaitModule, { children: _jsx(ShellProtected, {}) }), children: [_jsx(Route, { path: "".concat(routes.agencies, "/*"), element: _jsx(AgenciesRoutes, {}) }), _jsx(Route, { path: "".concat(routes.commissions, "/*"), element: _jsx(CommissionRoutes, {}) }), _jsx(Route, { path: "".concat(routes.serviceFee, "/*"), element: _jsx(ServiceFeeRoutes, {}) }), _jsx(Route, { path: "".concat(routes.markup, "/*"), element: _jsx(MarkupRoutes, {}) }), _jsx(Route, { path: "".concat(routes.branches, "/*"), element: _jsx(BranchesRoutes, {}) }), _jsx(Route, { path: "".concat(routes.searchModules, "/*"), element: _jsx(SearchModulesRoutes, {}) }), _jsx(Route, { path: "".concat(routes.airlinesCommissions, "/*"), element: _jsx(ListContextProvider, { children: _jsx(AirlinesCommissionsRoutes, {}) }) })] }), _jsx(Route, { path: routes.accessDenied, element: _jsx(BlockedAccess, { appName: "Control Panel" }) })] })));
